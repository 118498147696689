export default {
  t_welcome: "Hoşgeldiniz", //Login 
  t_copyright: "Tüm Hakları Saklıdır ©️ 2023 ver3.1.0",
  t_copy: "Kopyala",
  t_userLogin: "Kullanıcı Girişi",
  t_userName: "Kullanıcı Adı",
  t_password: "Parola",
  t_login: "Giriş",
  t_hello: "Merhaba",
  t_usernameOrPasswordIsInvalid: "Kullanıcı adı veya parola hatalı.",
  t_successfullyLoggedIn: "Başarılı bir şekilde giriş yapıldı.",
  t_pleaseSelectQueueAndClassification: "Lütfen kuyruk ve sınıflandırma seçiniz",
  t_PleaseSelectProjetAndQueues: "Lütfen proje ve kuyruk seçimi yapınız",
  t_pleaseSingIn: "Lütfen giriş yapınız",
  t_rememberMe: 'Beni Hatırla',
  t_signIn: 'Giriş Yap',
  t_changeStatusAfterTheCall: "Çağrı Sonrası Durum Değiştir", //LoyoutContentRedererDefault.vue // 
  t_remainingTime: "Kalan Süre",
  t_continue: "Devam Et",
  t_hold: "Beklet",
  t_microphoneOn: "Mikrofon Aç",
  t_microphoneOff: "Mikrofon Kapa",
  t_inputList: "Tuşlama Listesi",
  t_showInputs: "Tuşlamaları Göster",
  t_chatPanel: "Chat Paneli",
  t_showDetail: "Detay Göster",
  t_connected: "Bağlı", // Navbar.vue 
  t_disconnected: "Bağlı Değil",
  t_dayMode: "Gündüz Modu",
  t_nightMode: "Gece Modu",
  t_agentList: "Temsilci Listesi",
  t_logOut: "Çıkış", // AgentTargets // 
  t_collectionTargeting: "Hedefleme",
  t_targeting: "Hedefleme",
  t_dashboard: "Pano",
  t_search: "Ara",
  t_add: "Ekle",
  t_targetedMonth: "Hedeflenen Ay",
  t_confirm: "Tamam",
  t_areYouSureYouWantToDeleteTheSelectedRecord: "Seçili kaydı silmek istediğinizden emin misiniz?",
  t_areYouSureYouWantToUpdateTheSelectedRecords: "Seçili kayıtları güncellemek istediğinizden emin misiniz?",
  t_areYouSureYouWantToClearAll: "Tümünü temizlemek istediğinizden emin misiniz?",
  t_selectTheAgent: "Temsilci Seçiniz",
  t_collectedDate: "Gerçekleşme Tarihi",
  t_collectedTime: "Gerçekleşme Zamanı",
  t_edit: "Düzenle",
  t_configuration: "KONFİGÜRASYON",
  t_configuration2: "Konfigürasyon",
  t_completed: "Tamamlanan",
  t_remaining: "Kalan",
  t_compilationRate: "Tamamlama Oranı",
  t_minute: "Dakika",
  t_targetCompletion: "Hedef Tamamlama",
  t_hour: "Saat",
  t_day: "Gün",
  t_week: "Hafta",
  t_month: "Ay",
  t_year: "Yıl",
  t_weekday: "Çalışma Günü",
  t_lastOneHour: "Son 1 Saat",
  t_lastThreeHours: "Son 3 Saat",
  t_twoDaysAgo: "Son 2 Gün",
  t_threeDaysAgo: "Son 3 Gün",
  t_fourDaysAgo: "Son 4 Gün",
  t_fiveDaysAgo: "Son 5 Gün",
  t_sixDaysAgo: "Son 6 Gün",
  t_period: "Periyot",
  t_thisWeek: "Bu Hafta",
  t_lastWeek: "Geçen Hafta",
  t_get: "Getir",
  t_saveFilters: "Filtreleri Kaydet",
  t_target: "Hedef",
  t_announcements: "Anonslar", // Announcements // 
  t_announcementName: "Anons Adı",
  t_selectAudioFile: "Ses Dosyası Seçiniz",
  t_announcementList: "Anons Listesi",
  t_areYouSure: "Emin Misiniz?",
  t_ok: "Tamam",
  t_count: "Adet",
  t_CompletedAmount: "Tamamlanan Tutar",
  t_dailyTarget: "Günlük Hedef",
  t_monthlyTarget: "Aylık Hedef",
  t_dailyTimeline: "Günlük zaman çizelgesi",
  t_blackList: "Kara Liste", // BlackListManagement // 
  t_descriptions: "Açıklama",
  t_inbound: "Gelen",
  t_outbound: "Giden",
  t_number: "Numara",
  t_callHistory: "Çağrı Geçmişi", // CallHistory // 
  t_numberHistory: "Numara Geçmişi",
  t_notes: "Notlar",
  t_oldNotes: "Eski Notlar",
  t_campaigns: "Kampanyalar", // Campaigns // 
  t_campaign: "Kampanya",
  t_campaignName: "Kampanya Adı",
  t_campaignList: "Kampanya Listesi",
  t_campaignhasBeenDeletedSuccessfully: "kampanyası başarılı bir şekilde silindi.",
  t_campaignhasBeenUpdatedSuccessfully: "kampanyası başarılı bir şekilde güncellendi.",
  t_campaignhasBeenCreatedSuccessfully: "kampanyası başarılı bir şekilde güncellendi.",
  t_customTabs: "Özel Sekmeler", // CustomTabs // 
  t_address: "Adres",
  t_landlinePrefix: "Sabit Prefix",
  t_fctPrefix: "FCT Prefix",
  t_customTabList: "Özel Sekme Listesi",
  t_dataManagement: "Veri Yönetimi", // DataUpload // 
  t_productManagement: "Ürün Yönetimi",
  t_scriptManagement: "Script Yönetimi",
  t_exampleFile: "Örnek Excel",
  t_uploadFile: "Dosya Yükle",
  t_SelectTheTitlesToUpdate: "Lütfen güncellenecek başlıkları seçiniz",
  t_setUnmatchedRecordsToPassive: "Eşleşmeyen kayıtları pasif yap",
  t_setUnmatchedRecordsToUpdate: "Eşleşen kayıtların telefonlarını ve ürünlerini güncelle",
  t_setAllPreviousInstitutionDataToPassive: "Kurum adına ait tüm veriyi pasif yap",
  t_updateMatchedRecords: "Eşleşen kayıtları güncelle",
  t_setAllRecordsOfProjectNameToPassive: "Kurum adına ait tüm veriyi pasif yap",
  t_upload: "Yükle",
  t_0customersHasBeenUpdatedSuccessfully: "0 adet müşteri başarılı bir şekilde yüklendi.",
  t_customersHasBeenUpdatedSuccessfully: "adet müşteri başarılı bir şekilde yüklendi.",
  t_customersCouldNotBeUploadedBecauseThereHasBeenAnError: "adet müşteri yüklenirken bir sorun oluştu.",
  t_pleaseSelectAProject: "Proje seçmek için tıklayınız",
  t_dialPlan: "Arama Planı", // DialPlan // 
  t_classifications: "Sınıflandırmalar",
  t_simulation: "Simülasyon",
  t_check: "Sorgula",
  t_order: "Sıra", //DIAL PLAN PRIORITY1 
  t_callingPatterns: "Arama Deseni",
  t_dialPattern: "Yönlendirme Deseni",
  t_simulatedCallCouldBePlacedFrom: "Çıkma sırası: ",
  t_couldNotPlaceSimulationCall: "Çağrı çıkmadı!",
  t_totalAgents: "Toplam", // Live Dashboard // 
  t_conference: "Konferans",
  t_callsInQueue: "Bekleyen",
  t_abandonedCount: "Kaçan",
  t_hourlyAbandonedCount: "Saatlik Kaçan Adetleri",
  t_statusLength: "Süre",
  t_massChangeStatuses: "Toplu Durum Değiştirme",
  t_textColour: "Metin Rengi",
  t_audioFile: "Ses Dosyası",
  t_type: "Tür",
  t_confetti: "Konfeti",
  t_champagne: "Şampanya",
  t_emergency: "Acil",
  t_blink: "Yanıp - Sönme",
  t_wallboardControl: "Wallboard Kontrol",
  t_writtenSouffle: "Yazılı Sufle",
  t_listen: "Dinle",
  t_clap1: "Alkış1",
  t_clap2: "Alkış2",
  t_warning: "İkaz",
  t_changeAllStatuses: "Toplu Durum Değişimi",// Home.vue 
  t_showWentHome: "Eve Gittileri Göster", // Home.vue 
  t_hideWentHome: "Eve Gittileri Gizle", // Home.vue 
  t_showCalledBack: "Geri Arananları Göster",// Home.vue 
  t_reakTimeFraud: "Mola Aşımı", // Home.vue 
  t_finishCodeCategory: "Kategori", // FinishCodeManagement // 
  t_categoryName: "Kategori Adı",
  t_finishCodeCategorys: "Sonuç Kategorileri",
  t_finishCodeName: "Görünen Ad",
  t_finishCodes: "Sonuç Kodları",
  t_askForActionDate: "Tarih Sor",
  t_saveToCalendar: "Hatırlat",
  t_contactCallableAfterwards: "Kontak Aranabilir",
  t_contactNumberCallableAfterwards: "Telefon Aranabilir",
  t_countAsCallAttempt: "Temas Var",
  t_lastFinishCode: "Son Sonuç Kodu",
  t_deleteContactNumber: "Telefonu Sil",
  t_assignToAgent: "Atama Yap",
  t_inboundRoutes: "Gelen Yönlendirmeler", // InboundRoutes // 
  t_standardRoute: "Standart Yönlendirme",
  t_lineGroup: "Hat Grubu", // LineGroup // 
  t_lineGroupName: "Hat Grubu Adı",
  t_lineGroups: "Hat Grupları",
  t_addedLines: "Eklenen Hatlar",
  t_lineList: "Hat Listesi", // Lines // 
  t_importLines: "Hatları İçeri Aktar",
  t_lineName: "Hat Adı",
  t_outboundCID: "Çıkış Numarası",
  t_transferCID: "Dış Yönlendirme Numarası",
  t_hide_ofline_users: "Çevrimdışı Olanları Gizle",
  t_ip: "IP",
  t_transportType: "Transport Tipi",
  t_type: "Tipi",
  t_prefix: "Prefix",
  t_inboundCallLimit: "Gelen Çağrı Limiti",
  t_outboundCallLimit: "Giden Çağrı Limiti",
  t_port: "Port",
  t_codec: "Codec",
  t_fileUpload: "Dosya Yükleme",
  t_file: "Dosya",
  t_selectTheFileYouWantToUpload: "Yükleme yapmak istediğiniz dosyayı seçiniz",
  t_pleaseEnterTheLineGroupName: "Lütfen hat grubu adı girin.",
  t_youNeedToPickALineToBeAbleToCreateLineGroup: "Hat grubu oluşturabilmeniz için en az 1 tane hat seçmeniz gerekmektedir.",
  t_lineGroupHasBeenCreatedSuccessfully: "Hat grubu başarılı bir şekilde oluşturuldu.",
  t_campaignManagement: "Kampanya Yönetimi", // IvnDialer // 
  t_campaignControl: "Arama İşlemleri",
  t_stop: "Durdur",
  t_recycle: "Recycle",
  t_calls: "Çağrı",
  t_basic: "Temel",
  t_scheduledCalls: "Zamanı Gelmeyen",
  t_contactList: "Arama Listesi",
  t_programming: "Programlama",
  t_priority: "Öncelik",
  t_maxCall: "Maksimum Çağrı",
  t_callsPerAgent: "Temsilci Başına Çağrı",
  t_statisticsPeriod: "İstatistik Periyot (dk)",
  t_callingMethod: "Çıkış Methodu",
  t_noAnswerTimeout: "Cevapsız Süre Aşımı (sn)",
  t_maxAbandonRate: "Maksimum Kaçma Oranı (%)",
  t_dontConnectMachines: "Makine Cevaplarını Bağlama",
  t_sortCallList: "Arama Sıralaması",
  t_maxCycles: "Maksimum Döngü",
  t_recallDelayMins: "Sonraki Arama Süresi (dk)",
  t_afterCall: "Çağrı Sonrası",
  t_afterCallStatus: "Çağrı Sonrası Statü",
  t_maxAttempts: "Max. Temas",
  t_callstatisticsfortoday: "Bugün gerçekleşen çağrı istatistikleri",
  t_statusdurationfortoday: "Bugün gerçekleşen durum süreleri",
  t_maximumRateOfCallsThatAreAbandonedFromTheQueue: "Kuyruktan kaçan çağrıların erişebileceği maks. oran",
  t_maximumAmountOfCallsThisCampaignCanStart: "Kampanyanın anlık çıkabileceği maksimum çağrı sayısı",
  t_callsPerAvailableAgents: "Temsilci başına çıkılacak çağrı sayısı",
  t_refreshRateOfThisCampaignsStatisticsGraphs: "Kampanyaya ait grafiğin yenilenme sıklığı",
  t_whichLineGroupToMakeCallsFrom: "Kampanyanın çağrı çıkacağı hat grubu",
  t_maximumRingingTime: "Çıkılan çağrının karşı tarafta maksimum çalma süresi",
  t_amountOfRoundsToCallPeopleWhoHaventBeenContactedYet: "Arama listesinde tamamlandığında, temas kurulamayanlara için tekrar başlatılacak döngü sayısı",
  t_minimumAmountOfMinutesToRecallAPhoneNumber: "Arama yapılan bir numaraya, minumum kaç dakika sonra tekar aranacağı süre",
  t_maximumCallAmountPerPhoneNumberPerDay: "Telefona bir günde yapılacak maksimum çağrı sayısı",
  t_maximumCallAmountPerContactPerDay: "Kontağa bir günde yapılacak maksimum çağrı sayısı",
  t_maximumCallAmountPerPhoneNumber: "Telefona toplam yapılacak maksimum çağrı sayısı",
  t_maximumCallAmountPerContact: "Kontağa toplam yapılacak maksimum çağrı sayısı",
  t_doNotConnectMachineAnsweredCalls: "Cevaplan çağrı makine çağrısı ise temsilciye bağlama",
  t_dailyMaxPhoneTouch: "Günlük Max. Temas Telefon",
  t_dailyMaxContactTouch: "Günlük Max. Temas Kontak",
  t_totalMaxPhoneTouch: "Toplam Max. Temas Telefon",
  t_totalMaxContactTouch: "Toplam Max. Temas Kontak",
  t_justCallMobilePhone: "Sadece GSM ara",
  t_abandoned: "Kaçan",
  t_timeCondition: "Zaman Koşulu",
  t_transferIVR: "IVR Aktar",
  t_liveContacttIVR: "Canlı Kontak IVR",
  t_liveContact: "Canlı Kontak",
  t_noAvailableAgent: "Uygun Temsilci Yok", // Dialer.vue // 
  t_noAvailableAgentIVR: "Uygun Temsilci Yok IVR",
  t_rule: "Kural",
  t_campaignStatus: "Durum",
  t_campaignPriority: "Öncelik",
  t_campaignFilterCriteria: "Filtre Kriteri",
  t_campaignSortingCriteria: "Sıralama Kriteri",
  t_campaignRemainingCycles: "Kalan Döngü",
  t_campaignCallPerAgent: "Temsilci Başına Çağrı",
  t_campaignTotalCustomersToCall: "Toplam Aranacak Müşteri",
  t_campaignTotalPhonesToCall: "Toplam Aranacak Telefon",
  t_campaignRemainingPhonesInCycle: "Döngüde Bekleyen",
  t_campaignTotalCalled: "Toplam Aranan",
  t_campaignTotalPhonesCalled: "Anlık Aranan",
  t_campaignConnectedCall: "Bağlanan Çağrı",
  t_campaignWaitingInQueue: "Bekleyen Çağrı",
  t_campaignAbandonRate: "Kaçma Oranı %",
  t_resultNoAnswer: "NoAnswer (Cevapsız)",
  t_resultAbandon: "Abandon (Kaçan)",
  t_resultBusy: "Busy (Meşgul)",
  t_resultCallRejected: "CallRejected (Müşteri Red)",
  t_resultFaultyCall: "FaultyCall (Hatalı Arama)",
  t_resultNetworkOutOfOrder: "NetworkOutOfOrder (Ağ Hatası)",
  t_resultFateUnknown: "FateUnknown (Bilinmiyor)",
  t_resultConnectivityError: "ConnectivityError (Bağlantı Hatası)",
  t_resultFaultyNumber: "FaultyNumber (Hatalı Numara)",
  t_finishCodeStatistics: "Sonuç Kodları Dağılımı",
  t_customersStatus: "Durum",
  t_assigned_agent: "Agent",
  t_attempts: "Temas",
  t_dial_plan: "Arama Planı",
  t_phone_book: "Rehber",
  t_ivr: "IVR",
  t_finish_code: "Sonuç Kodu",
  t_insert_date: "Arama T.",
  t_action_date: "Aranacak T.",
  t_create_date: "Yüklenme T.",
  t_ex_agent: "Ex Agent",
  t_assign_date: "Atama T.",
  t_pleaseMakeASelectionForSorting: "Sıralama yapmak için lütfen tek seçim yapınız.",
  t_started: "Başlatıldı",
  t_stopped: "Durduruldu",
  t_recycling: "Yenileniyor",
  t_isCompleted: "Tamamlandı",
  t_settingsHasBeenSavedSuccessfully: "Ayarlar başarılı bir şekilde kaydedildi.",
  t_columns: "Başlıklar", //Main.vue 
  t_row: "Satır",
  t_sort: "Sıralama", //Main.vue 
  t_algorithmicDialer: "Algoritmik Otomatik Arama", // List.vue 
  t_basicDialer: "Temel Otomatik Arama", // List.vue 
  t_filterManagement: "Filtre Yönetimi", // IvnDialerFilter // 
  t_noFiltersForThisCampaign: "Kampanyaya ait filtre bulunamadı.", // IvnDialerFilter > t_fiterManagemt > t_value 
  t_condition: "Koşul",
  t_equal: "Eşittir",
  t_notEqual: "Eşit Değildir",
  t_lessThan: "Küçüktür",
  t_greaterThan: "Büyüktür",
  t_lessThanOrEqual: "Küçük Eşittir",
  t_greaterThanOrEqual: "Büyük Eşittir",
  t_columnName: "Başlık",
  t_columnNameStatus: "Durum",
  t_columnNameAssignedAgent: "Agent",
  t_columnNameAttempts: "Temas",
  t_columnNameCampaign: "Kampanya",
  t_columnNameFinishCode: "Son Sonuç Kodu",
  t_columnNameInsertDate: "Arama T.",
  t_columnNameActionDate: "Aranacak T.",
  t_columnNameCreateDate: "Yüklenme T.",
  t_columnNameExAgent: "Ex Agent",
  t_filterHasBeenCreatedSuccessfully: "Filtre başarılı bir şekilde oluşturuldu.",
  t_IVR: "IVR", // IvrManagement // 
  t_IVRName: "IVR Adı",
  t_IVRList: "IVR Listesi",
  t_DTMFdigit: "Tuşlama",
  t_announcementFile: "Anons Dosyası",
  t_DTMFAccept: "Tuşlama Kabul Et",
  t_extension: "Dahili",
  t_transfer: "Transfer",
  t_language: "Dil",
  t_timeOutLengthSec: "Zaman Aşım Süresi (Sn)",
  t_timeOutCount: "Zaman Aşım Adedi",
  t_maxInvalidInputCount: "Yanlış Tuşlama Adedi",
  t_acceptDTMFExtension: "Dahili Tuşlama Kabul Et",
  t_turkish: "Türkçe",
  t_english: "İngilizce",
  t_arabic: "Arapça",
  t_russian: "Rusça",
  t_hangup: "Sonlandır",
  t_hangupCall: "Reddet",
  t_incomingCall: "Gelen Arama",
  t_answer: "Cevapla",
  t_menu: "Menü",
  t_matched: "Eşleşen:",
  t_unmatched: "Eşleşmeyen:",
  t_hasBeenDeletedSuccessfully: "Başarılı bir şekilde silindi.",
  t_pleaseDoNotLeaveAnyBlankFields: "Lütfen boş alan bırakmayınız.",
  t_hasBeenUpdatedSuccessfully: "Başarılı bir şekilde güncellendi.",
  t_hasBeenCreatedSuccessfully: "İşlem başarılı bir şekilde gerçekleşti.",
  t_pleaseSelectTheBlackListDirection: "Lütfen engelleme yapmak istediğiniz yönü seçin.", // blacklist 
  t_phoneBook: "Rehber", // PhoneBook // 
  t_description: "Açıklama",
  t_profiles: "Profiller", // ProfileQueueManagement // 
  t_profileName: "Profil Adı",
  t_affectedObjects: "Etkilenen Nesneler",
  t_permissions: "Yetkilendirmeler",
  t_user: "Kullanıcı",
  t_reset: "Sıfırla",
  t_noPermissionsSelected: "Hiçbir yetki seçilmedi.",
  t_profileList: "Profil Listesi",
  t_chat: "Mesajlaşma",
  t_souffle: "Sufle",
  t_profileHasBeenDeletedSuccessfully: "profili başarılı bir şekilde silindi.",
  t_profileHasBeenUpdatedSuccessfully: "profili başarılı bir şekilde güncellendi.",
  t_profileHasBeenCreatedSuccessfully: "Profil başarılı bir şekilde oluşturuldu.",
  t_is_visible_in_live_dasboard: "Listede Göster",
  t_projectName: "Proje Adı", // Projects // 
  t_afterCallWork: "Çağrı Sonrası Süresi (sn)",
  t_cameraRecord: "Kamera Kaydı Al",
  t_useableBreakTime: "Kullanılabilir Mola Süresi (dk)",
  t_breakTime: "Mola Süresi",
  t_surname: "Soyad",
  t_nameSurname: "Adı Soyadı",
  t_projectList: "Proje Listesi",
  t_projectHasBeenDeletedSuccessfully: "projesi başarılı bir şekilde silindi.",
  t_projectHasBeenUpdatedSuccessfully: "projesi başarılı bir şekilde güncellendi.",
  t_projectHasBeenCreatedSuccessfully: "Proje başarılı bir şekilde oluşturuldu.",
  t_queueNumber: "Kuyruk Numarası", // Queues - List.vue // 
  t_queueAgentTimeout: "Temsilci Zaman Aşımı (sn)",
  t_queueAgentTimeoutPlaceholder: "Çağrının, temsilcide bekleyeceği maksimum süre",
  t_queueAnnounceFrequency: "Anons Sıklığı (sn)",
  t_timeBetweenAnnouncements: "Anonslar arası geçen süre",
  t_queueAnnouncePosition: "Pozisyon Anonsu",
  t_queueMaxlen: "Maksimum Uzunluk",
  t_queuePenaltyMembersLimit: "Penaltı Üye Limiti",
  t_queueRetry: "Yeniden Deneme",
  t_queueMusicOnHold: "Bekleme Müziği",
  t_queueName: "Kuyruk Adı",
  t_queueCallingMode: "Arama Modu",
  t_queueStrategy: "Kuyruk Stratejisi",
  t_queueStrategyPlaceholder: "Çağrıların, temsilciler arasında dağıtım stratejisi",
  t_queueWrapupTime: "Çağrı Sonrası Süre (sn)",
  t_queueWrapupTimePlaceholder: "Çağrıdan sonra, temsilcilerin çağrısız kalabileceği minimum süre",
  t_queueAnnounceHoldTime: "Bekleme Süresi Anonsu",
  t_queueQueueHoldTime: "Otomatik Bekleme Süresi",
  t_queueMemberDelay: "Üye Gecikmesi",
  t_queuePeriodicAnnounceFrequency: "Periyodik Anons Frekansı",
  t_queueServiceLevel: "Servis Seviyesi",
  t_queueWeight: "Ağırlık",
  t_queueList: "Kuyruk Listesi",
  t_queueRrmemory: "Sıralı (Önerilen)",
  t_queueRingall: "Herkes Aynı Anda",
  t_queueLeastrecent: "En Erken Aranan Önce",
  t_queueAgentWeight: "Temsilci Önceliği",
  t_queueFewestcalls: "En Az Çağrı Almış Önce",
  t_queueRandom: "Rastgele",
  t_queueManual: "Manuel",
  t_queueDialer: "Dialer",
  t_serviceManagement: "Servis Yönetimi", // ServiceManagement // 
  t_dialerService: "Oto. Arama Servisi",
  t_restart: "Yeniden Başlat",
  t_callManager: "Çağrı Yönetim Servisi",
  t_chatService: "Mesajlaşma Servisi",
  t_smsSending: "SMS Gönderim", //SMS// 
  t_smsTemplate: "Şablon",
  t_smsFilter: "Filtre",
  t_filterName: "Filtre Adı", // SmsFilter // 
  t_noFilterHasBeenSelected: "Herhangi bir filtre seçilmedi.",
  t_filterList: "Filtre Listesi",
  t_smsPageTitle: "Sayfa Adı", // SmsSender // 
  t_sendFromVoyceCRM: "Sistemden Gönderim",
  t_sendFromExternalCRM: "Dış Kaynaktan Gönderim",
  t_sendFromCustomSms: "Özel Gönderim",
  t_smsPattern: "Mesaj Şablonu",
  t_preview: "Önizleme",
  t_exampleText: "Örnek Mesaj Metni",
  t_phoneNumbers: "Telefon Numaraları",
  t_enterPhoneNumbersSeparatedByCommas: "Telefon numaralarını virgül ile ayırarak giriniz.",
  t_enterEmailsSeparatedByCommas: "Mail adreslerini virgül ile ayırarak giriniz.",
  t_pleaseEnterAtLeastOnePhoneNumber: "Lütfen en az bir telefon numarası giriniz.",
  t_pleaseEnterAMessage: "Lütfen bir mesaj giriniz.",
  t_messagesHaveBeenDeliveredSuccessfully: "Mesajlar başarılı bir şekilde iletilmiştir.",
  t_thereWasAProblemDeliveringYourMessages: "Mesajlarınız iletilirken bir sorun oluştu.",
  t_displayName: "Görünen Ad", // SmsSettings // 
  t_senderNumber: "Gönderici Numarası",
  t_accountCode: "Hesap Kodu",
  t_apiKey: "API Anahtarı",
  t_smsSettingList: "SMS Ayar Listesi",
  t_pattern: "Şablon",
  t_patternName: "Şablon Adı",
  t_smsText: "Mesaj Metni",
  t_patternList: "Şablon Listesi",
  t_callCenter: "Çağrı Merkezi", // Statistics // 
  t_statistics: "İstatistikler",
  t_missedCall: "Kaçan",
  t_lunch: "Yemek",
  t_break: "Mola",
  t_AVGInboundLengt: "Gelen Ortalama",
  t_AVGOutboundLengt: "Giden Ortalama",
  t_totalInbound: "Gelen Toplam",
  t_totalOutbound: "Giden Toplam",
  t_remainingBreakTime: "Kalan Mola",
  t_statusTime: "Durum Süreleri",
  t_totalActionCounts: "Aksiyon Adetleri",
  t_waitingInQueue: "Kuyrukta Bekleyenler",
  t_numberofwaitingininstantqueue: "Anlık kuyrukta bekleyen sayısı",
  t_allcallrecordsfortoday: "Bugün gerçekleşen tüm çağrı kayıtları",
  t_collectionTarget: "Hedef",
  t_collectedMade: "Gerçekleşen",
  t_workingDay: "Çalışma Günü",
  t_present: "Devamlılık",
  t_absent: "Devamsızlık",
  t_actionDate: "Aksiyon",
  t_inCallOutbound: "Çağrıda (Giden)",
  t_inCallInbound: "Çağrıda (Gelen)",
  t_ringing: "Çalıyor",
  t_noAnswer: "Cevapsız",
  t_promiseToPay: "Ödeme Sözü",
  t_refuseToPay: "Ödeme Red",
  t_messageLeft: "Not Bırakıldı",
  t_busy: "Meşgul",
  t_statusCategory: "Durum Kategorisi", // StatusManagement // 
  t_statusCategorys: "Durum Kategorileri",
  t_statusName: "Durum Adı",
  t_categoryKey: "Kategori Anahtarı",
  t_categories: "Kategoriler",
  t_changeable: "Değiştirilebilir",
  t_onlySPVCanChange: "Supervisor Değiştirebilir",
  t_active: "Aktif",
  t_statusList: "Durum Listesi",
  t_online: "Çevrimiçi",
  t_offline: "Çevrimdışı",
  t_other: "Diğer",
  t_important: "Önemli",
  t_apointment: "Randevu",
  t_reminder: "Hatırlatma",
  t_personal: "Kişisel",
  t_personaly: "Kişisel",
  t_timeConditionName: "Zaman Koşulu Adı", // TimeConditionManagement - List.vue 
  t_startDay: "Başlangıç Günü",
  t_startTime: "Başlangıç Zamanı",
  t_startingDayOfMonth: "Ayın Başlangıç Günü",
  t_monthStart: "Ay Başlangıcı",
  t_monthStartDay: "Ay Başlangıç Günü",
  t_monthEndDay: "Ay Bitiş Günü",
  t_monthEnd: "Ay Bitişi",
  t_endDay: "Bitiş Günü",
  t_endTime: "Bitiş Zamanı",
  t_endingDayOfMonth: "Ayın Bitiş Günü",
  t_timeConditions: "Zaman Koşulları",
  t_monday: "Pazartesi",
  t_tuesday: "Salı",
  t_wednesday: "Çarşamba",
  t_thursday: "Perşembe",
  t_friday: "Cuma",
  t_saturday: "Cumartesi",
  t_sunday: "Pazar",
  t_january: "Ocak",
  t_february: "Şubat",
  t_march: "Mart",
  t_april: "Nisan",
  t_may: "Mayıs",
  t_june: "Haziran",
  t_july: "Temmuz",
  t_august: "Ağustos",
  t_september: "Eylül",
  t_october: "Ekim",
  t_novenber: "Kasım",
  t_december: "Aralık",
  t_theBeginingConditionCanNotBeGraterThanEndingCondition: "Başlangıç koşulu, bitiş koşulundan büyük olamaz.",
  t_userInformations: "Kişisel Bilgiler",
  t_import: "İçeri Aktar", // UsersManagement // 
  t_importUsers: "İçeri Aktar", // UsersManagement // 
  t_importRecords: "İçeri Aktar", // UsersManagement // 
  t_userList: "Kullanıcı Listesi",
  t_tenantName: "Tenant Adı",
  t_recordingExport: "Ses Export", // VoiceExport.vue 
  t_recordingRemove: "Ses Silme", // VoiceExport.vue 
  t_filters: "Filtreler",
  t_tags: "Etiketler",
  t_nameType: "İsim Formatı", // VoiceExport.vue 
  t_youCanAddCommaBetweenEmailAddressesToAddMore: "E-Posta adresleri arasına virgül koyarak birden fazla e-posta adresi yazabilirsiniz",
  t_youCanAddCommaBetweenPhoneToAddMore: "Telfonlar arasına virgül koyarak birden fazla telefon numarası yazabilirsiniz",
  t_startDate: "Başlangıç Tarihi",
  t_endDate: "Bitiş Tarihi",
  t_namePattern: "İsim Formatı",
  t_targetMail: "Hedef E-Mail",
  t_whenTheFilesAreReadyYouWillbeSentAnEMail: "Dosyalar hazırlandığında mail adresinize otomatik olarak gönderilecektir.",
  t_fileType: "Dosya Formatı",
  t_theProcessHasBeenStartedItWillBeSentByEmailWhenItIsCompleted: "İşlem başlatılmıştır, tamamlandığında mail olarak iletilecektir.",
  t_thereWasAProblemStartingTheProcess: "İşlem başlatılırken bir sorun oluştu.",
  t_process: "İşlem", // VSPR // 
  t_deactiveCustomer: "Müşteri Kapama",
  t_activeCustomer: "Müşteri Açma",
  t_assigneManager: "Yönetici Atama",
  t_conclude: "Hitam Etme",
  t_addingNote: "Not Ekleme",
  t_deleteCallRecord: "Ses Kaydı Silme",
  t_matchingColumn: "Eşleştirilecek Sütun", // components - VSPR // 
  t_pleaseFillTheAllNecessaryAreas: "Lütfen bilgileri eksiksiz giriniz.",
  t_thereWasAProblemWhileAssigning: "Atama yapılırken bir sorun oluştu.",
  t_doesNotMatchAnyCustomers: "ile eşleşen kayıt yok.",
  t_matchingColumnMustBeTheSameInTheExcelColumn: "Eşleştirelecek sütun ismi, excel dosyasındaki sütun ismi ile aynı olmalıdır.",
  t_AProblemOccurredwhileProcessing: "İşlem yapılırken bir sorun oluştu.",
  t_customersHaveBeenSuccessfullyUpdated: "adet kayıt başarılı bir şekilde güncellendi.",
  t_customersHaveBeenSuccessfullyDeleted: "Kayıt başarılı bir şekilde silindi.",
  t_processSuccess: "İşlem Başarılı",
  t_assigne: "Atama Yap", // AssignManager // 
  t_manager: "Yönetici",
  t_thereWasAProblemWhileAssigningManager: "Yönetici atama yapılırken bir sorun oluştu.",
  t_managerHaveSuccessfullyBeenAssigned: "Yönetici atama başarılı bir şekilde yapıldı.",
  t_deactiveCustomersHaveSuccessfullyBeenDone: "adet kapama başarılı bir şekilde yapıldı.", // CloseCustomer // 
  t_VSPRCloseCustomer: "Kapama Yap",
  t_VSPROpenCustomer: "Data Aç",
  t_aProblemOccurredWhileConcluding: "Hitam etme yapılırken bir sorun oluştu.",
  t_customersHaveSuccessfullyBeenConcluded: "adet hitam etme başarılı bir şekilde yapıldı.",
  t_thereWasAProblemActivatingCustomers: "Data açma yapılırken bir sorun oluştu.",
  t_customersHaveBeenSuccessfullyActivated: "adet data başarılı bir şekilde açıldı.",
  t_deleteCallRecordings: "Ses Kayıtlarını Sil",
  t_fileHasBeenImportedSuccessfully: "Dosya başarılı bir şekilde yüklendi.",
  t_makeAnAnnouncement: "Anons Yap", // Chat // 
  t_sendASouffle: "Sufle Gönder",
  t_startChat: "Konuşma Başlat",
  t_open: "Aç",
  t_conversations: "Konuşmalar",
  t_announcementHistory: "Anons Geçmişi",
  t_totalCallTime: "Top. Konuşma Süresi",
  t_avgCallTime: "Ort. Konuşma Süresi",
  t_avgAnswerTime: "Çağrı Karşılama Hızı",
  t_avgHoldTime: "Ort. Hold Süresi",
  t_avgAcwTime: "Ort. ACW Süresi",
  t_totalCallTimeDescrition: "KPI",
  t_avgCallTimeDescrition: "KPI",
  t_avgAnswerTimeDescrition: "KPI",
  t_avgHoldTimeDescrition: "KPI",
  t_avgAcwTimeDescrition: "KPI",
  t_noRecordOnQueue: "Kuyrukta Bekleyen Çağrı Bulunmamaktadır",
  t_callRecords: "Çağrı Kayıtları",
  t_secondLine: "İkinci Hat",
  t_changeMode: "Görünümü Değiştir",
  t_showNotConnected: "Bağlı Olmayanları Göster",
  t_fullScreen: "Tam Ekran",
  t_announcementPanel: "Anons Paneli",
  t_yourMessage: "Mesajınız",
  t_startAconversation: "Konuşma Başlat",
  t_youGotAPrivateMessage: " size özel bir mesaj gönderdi.",
  t_startANewConversation: "Yeni Konuşma Başlatın",
  t_updatePassword: "Şifre Güncelleme", // Navbar // 
  t_PasswordCondition: "Şifreniz en az 6 karakter uzunluğunda olup, 1 harf ve 1 rakam içermelidir.",
  t_lastAccountEvent: 'Son Hesap Etkinliği',
  t_events: "Hareketler",
  t_changePassword: 'Şifre Değişikliği',
  t_again: "Tekrar",
  t_currentPassword: "Mevcut Şifreniz",
  t_newPassword: "Yeni Şifreniz",
  t_confirmPassword: "Yeni Şifreniz (Tekrar)",
  t_pleaseSelectfinishCode: "Lütfen sonuç kodu seçiniz",
  t_survey: "Anket",
  t_passwordmustContainAtLeast1LetterAnd1Number: "Şifre en az 1 harf ve 1 rakam içermelidir.",
  t_enteredpasswordsDoNotMatch: "Girilen şifreler eşleşmiyor.",
  t_passwordMustBeAtLeast6CharactersLong: "Şifre en az 6 karakter uzunluğunda olmalıdır.",
  t_YourPasswordCannotBeTheSameAsYourOldPassword: "Şifreniz, eski şifreniz ile aynı olamaz.",
  t_PasswordHasBeenchangedSuccessfully: "Şifre başarılı bir şekilde değiştirildi.",
  t_youHaveReconnectedToTheService: "Servise tekrar bağlandınız.",
  t_yourServiceConnectionHasBeenLost: "Servis bağlantınız kesildi.",
  t_ACWNothingSelected: "İşlem Yapılmadı",
  t_ACWTimeOut: "ACW Süre Aşımı",
  t_rdialerconnectedtoagent: "Otomatik Arama Bağlanan Çağrı",
  t_rdialerabandon: "Otomatik Arama Kaçan Çağrı",
  t_color: "Renk",
  t_view: "Görünüm",
  t_allDays: "Bütün Günler",
  t_addEvent: "Etkinlik Ekle",
  t_eventName: "Etkinlik Adı",
  t_eventNote: "Etkinlik Notu",
  t_eventDate: "Etkinlik Tarihi",
  t_eventTime: "Etkinlik Zamanı",
  t_displayOnCRM: "CRM'de Görüntüle",
  t_monthly: "Aylık",
  t_weekly: "Haftalık",
  t_daily: "Günlük",
  t_hourly: "Saatlik",
  t_mon: "Pzt",
  t_tue: "Sal",
  t_wed: "Çar",
  t_thu: "Per",
  t_fri: "Cum",
  t_sat: "Cmt",
  t_sun: "Pzr",
  t_monSat: "Pzt - Cmt",
  t_sunSat: "Pzr - Cmt",
  t_monFri: "Pzt - Cm",
  t_monWedFri: "Pzt, Çrş, Cm",
  t_eventhasBeenCreatedSuccessfully: "Etkinlik başarılı bir şekilde oluşturuldu.",
  t_avaragequalitypoint: "Ortalama Kalite Puanı",
  t_liveDashboard: "Canlı İzleme", // Menu // 
  t_mAlerts: 'Tetikleyiciler',
  t_systemManagement: "Sistem Yönetimi",
  t_operationManagement: "Operasyon Yönetimi",
  t_integrations: "Entegrasyonlar",
  t_eMail: "E-Mail",
  t_qVoiceMail: "Sesli Mail",
  t_lastLogin: "Son Giriş",
  t_speechRule: 'Konuşma Kuralı',
  t_addSpeechRule: 'Konuşma Kuralı Ekle',
  t_lines: "Hatlar",
  t_routes: "Yönlendirmeler", // System Management ->  Main 
  t_vIVR: "Değişkenli IVR",
  t_backup: "Yedekleme",
  t_providers: "Sağlayıcılar",
  t_mVoice: "Ses",
  t_reachedCalls: "Ulaşılan Arama",
  t_unreachedCalls: "Ulaşılamayan Arama",
  t_missedCalls: "Kaçan Çağrı",
  t_outcomingCall: "Giden Çağrı",
  t_inboundCall: "Gelen Çağrı",
  t_incomingCallAvg: "Gelen Çağrı Ortalama",
  t_outgoingCallAvg: "Giden Çağrı Ortalama",
  t_show: "Göster",
  t_entries: "kayıt",
  t_addRecord: "Kayıt Ekle",
  t_showing: "Gösteriliyor",
  t_title: "Ünvan",
  t_clear: "Temizle",
  t_perPage: "Sayfa başına",
  t_uploadFileOnClick: "Dosya yüklemek için tıklayınız",
  t_delete: "Sil",
  t_noEditedRecord: "Düzenlenecek kayıt bulunamadı.",
  t_noRecord: "Gösterilecek kayıt yok",
  t_errorFetchData: "Kayıt getirilirken bir sorun oluştu.",
  t_backToList: "Listeye Geri Dön",
  t_deleteRecord: 'Kaydı Sil',
  t_updateRecord: 'Kaydı Güncelle',
  t_titles: 'Başlıklar',
  t_searchPlaceHolder: 'Ad Soyad, Müşteri No, Ürün No, TC veya Telefon ile arama yapın',
  t_customerId: 'Müşteri ID',
  t_breakStatus: 'Mola Durumu',
  t_targetStatus: 'Hedef',
  t_mrAgentAssign: 'Temsilci Karıştırma',
  t_dataUpload: 'Veri Yükleme',
  t_customerList: 'Müşteri Listesi',
  t_customerListByPhone: 'Müşteri Listesi Telefon',
  t_customerListByProduct: 'Müşteri Listesi Ürün',
  t_customerListByCSV: 'Müşteri Listesi CSV',
  t_vehicle: 'Araç',
  t_landRegistry: 'Tapu',
  t_SSK: 'SSK',
  t_mernis: 'Mernis',
  t_gsmQuery: 'GSM Sorgu',
  t_negotiation: 'Müzakere',
  t_products: 'Ürünler',
  t_phones: 'Telefonlar',
  t_hourlyGraphic: 'Saatlik Grafik',
  t_deleteFilter: 'Filtreyi Sil',
  t_addFilter: 'Filtre Ekle',
  t_clearFilter: 'Filtreyi Temizle',
  t_addNewRecord: 'Yeni Kayıt Oluştur',
  t_selectableLines: "Seçilebilir Hatlar",
  t_selectedLines: "Kullanılan Hatlar",
  t_restartService: 'Servisi yeniden başlatmak istediğinizden emin misiniz?',
  t_restartServiceSuccessfully: 'Servis başarılı bir şekilde yeniden başlatıldı.',
  t_id: 'Sıra',
  t_goal: 'Hedef',
  t_goalManagementDescription: "Grafik görünümü",
  t_smscount: ' adet mesaj gönderilecektir. Onaylıyor musunuz?',
  t_call: 'Ara',
  t_clearFilters: "Filtereleri Temizle",  // CRM - Main.vue // 
  t_management: "Yönetim",
  t_customerInformation: "Müşteri Bilgileri",
  t_customerUpdate: "Müşteri Bilgilerini Güncelle",
  t_extraInformation: "Extra Bilgiler",
  t_hide: "Gizle",
  t_addAction: "Aksiyon Ekle",
  t_addPhone: "Telefon Ekle",
  t_addNote: "Not Ekle",
  t_landLine: "Sabit",
  t_addFinishCode: "Sonuç Kodu Ekle",
  t_addProductAction: "Ürün Aksiyonu Ekle",
  t_actionDate2: "Aksiyon T.",
  t_installmentCount: "Taksit Adedi",
  t_installmentAmount: "Taksit Tutarı",
  t_relationTo: "Sahibi",
  t_isApproved: "Onaylı",
  t_addData: "Veri Ekle",
  t_sendSMS: "SMS Gönder",
  t_sendEmail: "Email Gönder",
  t_filterTheResults: "Sonuçları Filtreleyin",
  t_filterBy: "Filtrele",
  t_pleaseWriteSomethingToSeeYourOptions: "Seçeneklerini görmek için birşeyler yazın.",
  t_columnVisibilityOptions: "Sütun Gösterim Ayarları",
  t_showHide: "(Göster/Gizle)",
  t_agentAssign: 'Temsilci Atama',
  t_assigmnentType: "Atama Tipi",
  t_contactPool: 'Havuz',
  t_sender: "Gönderen",
  t_shuffle: "Karıştırma",
  t_assignForProductAgent: 'Ürünler İçin Atama Yap',
  t_progresingDate: "İşlem T.",
  t_allUnassignedCasesWillBeAssignedToSelectedAgentsAreYouSure: "Boşta olan tüm dosyalar, seçilmiş olan temsilcilere atanacaktır. Onaylıyor musunuz?",
  t_amountOfCasesWillBeUpdatedAreYouSure: "kişi üzerinde değişiklik yapılacak. Onaylıyor musunuz?",
  t_casesCouldNotBeAssigned: "adet atama yapılırken bir sorun oluştu.",
  t_customersHaveSuccessfullyBeenAssigned: "adet atama başarılı bir şekilde yapıldı.",
  t_error: "Hata",
  t_failed: "Hata Oluştu",
  t_reportDetails: 'Rapor Detayları', // Reports.vue 
  t_summary: "Özet",
  t_today: "Bugün", // Reports.vue 
  t_yesterday: "Dün", // Reports.vue 
  t_last7Days: "Son 7 Gün", // Reports.vue 
  t_last30Days: "Son 30 Gün", // Reports.vue 
  t_betweenDates: "Tarih Aralığı", // Reports.vue 
  t_reports: 'Raporlar', //Main.vue
  t_reportType: 'Rapor Türü',
  t_callDetails: "Çağrı Detayları", // Report Names // 
  t_mrInboundCallDetails: "Gelen Çağrı Detayları",
  t_mrSL30Daily: "SL30 Günlük",
  t_mrSL30Monthly: "SL30 Aylık",
  t_mrSL30Hourly: "SL30 Saatlik",
  t_mrAbandonedCalls: "Kaçan Çağrılar",
  t_mrAfterWorkInboundCalls: "Mesai Dışı Gelen Çağrılar",
  t_mrTotalCalls: "Çağrı Adetleri",
  t_extraTime: "Ekstra Süre",
  t_giveExtraTime: "Ekstra Süre",
  t_time: "Süre (00:00:00)",
  t_mrCallDurationDetails: "Çağrı Süre Detayları",
  t_mrCallDurationSummary: "Çağrı Süre Özeti",
  t_mrInboundCallDaily: "Günlük Çağrı Dağılımı",
  t_mrInboundCallHourly: "Saatlik Çağrı Dağılımı",
  t_surveyDetails: "Anket Detayları",
  t_mrAgent: "Temsilci",
  t_mrAgentActivity: "Temsilci Özet",
  t_mrAgentCallCountsDaily: "Temsilci Günbazlı",
  t_mrAgentCallCountsByDate: "Tarama Temsilci Bazlı",
  t_mrAgentWork: "Temsilci Çalışma",
  t_mrAgentCalls: "Temsilci Arama",
  t_mrAgentDaily: "Günlük Çağrı Dağılımı",
  t_mrAgentHourly: "Saatlik Çağrı Dağılımı",
  t_mrAgentCalendar: "Takvim Detay",
  t_mrIvnDialerCallAnalyze: "Kampanya Analiz",
  t_mrIvnDialerCallDetails: "Kampanya Çağrı Detayları",
  t_called: "Taranan", // Dashboard.vue 
  t_closed: "Kapanan",
  t_backedupFiles: "Yedeklenen Dosyalar", // Backup.vue 
  t_pleaseSelectTheFileToUpload: "Veri tabanına yüklenecek olan dosyayı seçiniz",
  t_createBackup: "Yedek Oluştur",
  t_exportToDatabase: "Veri Tabanına Aktar",
  t_filter: "Filtre", // GLOBAL // 
  t_settings: 'Ayarlar',
  t_action: "Aksiyon",
  t_note: "Not",
  t_column: "Başlık",
  t_value: "Değer",
  t_operator: "Operatör",
  t_users: "Kullanıcılar",
  t_agents: "Temsilciler",
  t_date: "Tarih",
  t_agent: "Temsilci", // Home.vue 
  t_finishCode: "Sonuç Kodu",
  t_direction: "Yön",
  t_defaultRoute: "Standart Yönlendirme",
  t_editSelected: "Seçilenleri Güncelle",
  t_crm: 'CRM',
  t_calendar: 'Takvim',
  t_dialer: "Otomatik Arama",
  t_goalManagement: 'Hedef Yönetimi',
  t_line: 'Hat',
  t_dialPlans: 'Arama Planı',
  t_queues: 'Kuyruklar',
  t_projects: 'Projeler',
  t_statuses: 'Durumlar',
  t_announce: 'Duyuru',
  t_createScript: 'Konuşma metni oluştur',
  t_queueTimeOut: 'Kuyruk Zaman Aşımı',
  t_detinationType: 'Hedef Tipi',
  t_detinationTarget: 'Hedef',
  t_showInDashboard: 'Panoda Göster',
  t_reject: 'Reddet',
  t_internalCall: 'Dahili',
  t_isProductFinishCode: 'Ürün Sonuç Kodu',
  t_queueGroup: 'Kuyruk Grubu', //Home.vue 
  t_agentGroup: 'Temsilci Grubu', //Home.vue 
  t_selectableQueues: "Seçilebilir Kuyruklar",
  t_selectedQueues: "Kullanılan Kuyruklar",
  t_isHoldSound: 'Bekleme Müziği',
  t_waitTime: 'Bekleme Süresi',
  t_isReached: 'Telefon Açıldı',
  t_addCustomer: 'Müşteri Ekle',
  t_phonesUpdate: "Telefon Düzenle",
  t_phonesinfoUpdate: "Telefon Bilgilerini Güncelle",
  t_isAutoEmail: 'E-Mail Gönder',
  t_emailTemplate: 'E-Mail Şablonu',
  t_account: 'Hesap',
  t_security: 'Güvenlik',
  t_performance: 'Performans',
  t_emailDelayAmount: 'Dakika',
  t_isAutoSMS: 'SMS Gönder',
  t_smsDelayAmount: 'Dakika',
  t_autoAnswer: 'Otomatik Yanıtla',
  t_isUnassign: 'Atamayı Sil',
  t_isPassive: 'Pasife Çek',
  t_setAttemptsByPhone: 'Telefon Geçmişine Göre Temas Belirle',
  t_recordingFile: 'Kayıt',
  t_callStrategy: 'Arama Stratejisi',
  t_sortCriteria: 'Sıralama Kriteri',
  t_internationalCall: 'Uluslararası Arama',
  t_dialerPrecall: 'Dialer Precall',
  t_variable: 'Değişken',
  t_variableLength: 'Değişken Uzunluğu',
  t_reportFiles: 'Rapor Dosyaları', // Main.vue 
  t_schedule: 'Planla',
  t_scheduler: 'Planlayıcı',
  t_schedulerTasks: 'Planlananlar',
  t_schedulerCategory: 'Planlama Kategorisi',
  t_reportCategory: 'Rapor Kategorisi',
  t_lastXDays: 'Son X Gün',
  t_communicationType: 'İletişim Türü',
  t_communicationSrc: 'İletişim  Kaynağı',
  t_communicationTitle: 'Başlık',
  t_to: 'Alıcı (To)',
  t_cc: 'Karbon Kopya (CC)',
  t_youCanAddCommaBetweenEmailAddressesToaddMore: "E-Posta adresleri arasına virgül koyarak birden fazla e-posta adresi yazabilirsiniz",
  t_email: 'E-Posta',
  t_mobileNotification: 'Mobil Bildirim',
  t_callPlaced: 'Aranıyor',
  t_callWentToAgent: 'Görüşmede',
  t_callJoinedQueue: 'Kuyrukta',
  t_callPickedUp: 'Cevaplandı',
  t_callRejected: 'Reddedildi',
  t_schedulerTime: 'Planlama Zamanı',
  t_schedulerTimeString: 'Planlama Zamanı Açıklaması',
  t_creator: 'Oluşturan',
  t_nextRunTime: 'Sonraki Çalışma Zamanı',
  t_externalChat: 'Yazılı Kanal',
  t_start: "Başlat", // IvnDialer // 
  t_selectAll: "Tümünü Seç", // CRM - Main.vue - Home.vue // 
  t_clearAll: "Tümünü Temizle", // Main.vue 
  t_queue: "Kuyruk", // Home.vue - Reports.Vue 
  t_announcement: "Anons", //ProfileQueueManagement - Home.vue // 
  t_duration: "Süre", //Statistics - Home.vue 
  t_productivity: "Verimlilik", //Statistics - Home.vue 
  t_status: "Durum", // Home.vue 
  t_usedBreakTime: "Mola", //Statistics - Home.vue 
  t_answered: "Cevaplanan",  // Statistic.vue - Wallboard.vue - Home.vue 
  t_successfullCall: "Ulaşılan", //Statistics - Home.vue - Dialer.vue 
  t_unsuccessfullCall: "Ulaşılamayan", //Statistics - Home.vue - Dialer.vue 
  t_export: "Dışarı Aktar", // Home.vue - List.vue - Reports.vue 
  t_total: "Toplam", // Dialer.vue 
  t_rate: "Oran (%)", // Statistic.vue - Home.vue - Dialer.vue 
  t_send: "Gönder", //SmsFilter 
  t_next: 'İleri',
  t_previous: 'Geri',
  t_classification: "Sınıflandırma", // List.vue - ListAddNew.vue 
  t_available: "Uygun", // Wallboard.vue - Home.vue / Dialer.vue 
  t_inCall: "Çağrıda", // Wallboard.vue - Home.vue 
  t_paused: "Molada",  // Wallboard.vue - Home.vue 
  t_profile: "Profil",
  t_no: "Hayır",
  t_yes: "Evet",
  t_report: 'Rapor',
  t_actions: "Aksiyonlar", // All 
  t_cancel: "İptal", // All 
  t_save: "Kaydet", // All 
  t_category: "Kategori", // All 
  t_project: "Proje", // All 
  t_close: "Kapat", // All 
  t_name: "İsim", // All 
  t_sms: 'SMS', // All 
  t_general: 'Genel',
  t_loginPageImages: 'Giriş Ekranı Resimleri',
  t_browse: 'Seç',
  t_thisMonth: 'Bu Ay',
  t_lastMonth: 'Geçen Ay',
  t_yourReportAddToReportQueue: 'Raporunuz Rapor Kuyruğuna Eklendi',
  t_notification: 'Bildirim',
  t_notifications: 'Bildirimler',
  t_pleaseSelectReport: 'Lütfen Rapor Seçiniz',
  t_host: 'Sunucu',
  t_subject: 'Konu',
  t_isSendMailToCustomer: 'Müşteriye Mail Gönder',
  t_surveyCancel: 'Anketi İptal Et',
  t_surveyApprove: 'Anketi Onayla',
  t_sendType: 'Gönderim Türü',
  t_info: 'Bilgi',
  t_pleaseSelectTimeCondition: 'Lütfen Zaman Koşulu Seçiniz',
  t_crmWhiteColorInfo: 'Dokunulmamış',
  t_crmInfoColorInfo: 'Dokunulmuş ve Aranabilir',
  t_crmWarningColorInfo: 'Dokunulmuş ve Aranamaz',
  t_crmSecondaryColorInfo: 'Dokunulmuş ve Kapatılan',
  t_crmDangerColorInfo: 'Dokunulmamış ve Kapatılan',
  t_nextCustomer: 'Sonraki Müşteri',
  t_lineChangeInfo: 'Lütfen ilgili hat grubunu kullanan kampanyalarınız tekrar kayıt edin.',
  t_deleteData: 'Data Silme',
  t_closedByDailyAttempts: 'Günlük Temasla Kapanan',
  t_closedByTotalAttempts: 'Toplam Temasla Kapanan',
  t_resetScheduledCalls: 'Zamanı Gelmeyen Çağrıları Sıfırla',
  t_resetDailyAttempts: 'Günlük Temastan Kapananları Aç',
  t_resetTotalAttempts: 'Toplam Temastan Kapananları Aç',
  t_detail: "Detay",
  t_spokenCall: 'Görüşülen',
  t_spokenCallRate: 'Görüşme%',
  t_nonSpokenCall: 'Görüşülemeyen',
  t_nAttemptsCall: 'Temassız',
  t_attemptsRate: 'Temas%',
  t_reachRate: 'Ulaşım%',
  t_totalCallInfo: 'Arama yapılan toplam çağrı sayısı',
  t_spokenCallInfo: 'Temsilcinin konuştuğu çağrı sayısı',
  t_spokenRateInfo: 'Temas olan çağrıların görüşme sayısına oranı. Formül : Görüşülen / Toplam-Temassız',
  t_noSpokenCallInfo: 'Temas edilip görüşme olmayan çağrıların sayısı. Cevapsız ve Meşgul çağrılar',
  t_noAttemptsCallInfo: 'Sistem tarafından operatöre iletilen fakat operatörün aranılan numaraya temas etmeden sonlanan çağrı adedi',
  t_attemptsRateInfo: 'Sistem tarafından operatöre iletilen çağrıların aranılan numaraya temas etme oranı. Formül: Toplam - Temassız / Toplam',
  t_reachRateInfo: 'Aranan numaraların kuyruğa bağlanma oranı. Formül : Görüşülen + Kaçan / Toplam',
  t_username: 'Kullanıcı Adı',
  t_examples: 'Örnekler',
  t_cron: 'Zamanlayıcı',
  t_cron1: "Her dakika",
  t_cron2: "Her saat başı",
  t_cron3: "Her gün gece yarısı",
  t_cron4: "Her gün saat 17:00",
  t_cron5: "Her hafta Pazar günü gece yarısı",
  t_cron6: "Her ayın ilk günü gece yarısı",
  t_cron7: "Yılın ilk günü gece yarısı",
  t_cron8: "Haftanın her iş günü gece yarısı",
  t_cron9: "Her ayın 15. günü gece yarısı",
  t_cron10: "Her yılın ilk Pazartesi günü gece yarısı",
  t_cron11: "Her yılın Ocak, Haziran ve Aralık aylarının 15. günü gece yarısı",
  t_cron12: "Her ayın ilk iş günü gece yarısı",
  t_cron13: "Hafta sonları gece yarısı",
  t_alert: 'Alarm',
  t_lessThan: 'Küçüktür',
  t_lessThanOrEqual: 'Küçük Eşittir',
  t_moreThanOrEqual: 'Büyük Eşittir',
  // Report Fields
  t_rConferenceEvent: 'Konferans',
  t_rConferenceParticipants: 'Konferans Katılımcıları',
  t_rQualityFormNote: 'Form Notu',
  t_rInboundFailSL3059: '30/59sn İçinde Kaçan Çağrı A.',
  t_rInboundFailSL60119: '60/119sn İçinde Kaçan Çağrı A.',
  t_rInboundFailSL120179: '120/179sn İçinde Kaçan Çağrı A.',
  t_rInboundFailSL180: '180sn Aşarak Kaçan Çağrı A.',
  t_rIvrWaitLength: 'IVR Bekleme Süresi',
  t_rExtra: 'Ekstra Bilgiler',
  t_rSender: 'Gönderici',
  t_rMessage: 'Mesaj',
  t_rEvaluateEvent: 'Değerlendirme',
  t_rEvaluateTime: 'Değerlendirme T.',
  t_rQualityFormName: 'Form Adı',
  t_callAnalyze: 'Çağrı Analizi',
  t_rObjectionEvent: 'İtiraz',
  t_rPunishEvent: 'Ceza',
  t_rQualityPoint: 'Kalite Puanı',
  t_rMailSender: "Gönderici",
  t_rSubject: "Konu",
  t_rBody: "Açıklama",
  t_rTo: 'Alıcı (To)',
  t_rCC: 'Karbon Kopya (Cc)',
  t_rState: "Durum",
  t_rStartTime: "Başlangıç T.",
  t_rEndTime: "Bitiş T.",
  t_rRemoteNumber: "Telefon",
  t_rInboundFailSuccessCallBack: "Geri Aranan",
  t_rInboundFailDistinct: "Tekil Geri Aranmayan",
  t_rProductivity: "Verimlilik",
  t_rSurveyCount: "Anket Sayısı",
  t_rAgent: "Temsilci",
  t_rDirection: "Yön",
  t_rQueue: "Kuyruk",
  t_rVerdict: "Sonuç Kodu",
  t_rActionDate: "Aksiyon T.",
  t_rNote: "Not",
  t_rConnectedLength: "Görüşme Süresi",
  t_rQueueWaitLength: "Kuyrukta Bekleme S.",
  t_rHoldLength: "Bekletme Süresi",
  t_rAcwLength: "Acw Süresi",
  t_rRecordingFile: "Dosya Adı",
  t_rTerminateSide: "Sonlandıran",
  t_rRecordingFileName: "Kayıt",
  t_rLocalNumber: "Hat",
  t_rLineName: "Hat Adı",
  t_rClassification: "Sınıflandırma",
  t_rCampaign: "Kampanya",
  t_rInteractionID: "Çağrı ID",
  t_rMüşteriNo: "Müşteri No",
  t_rAdıSoyadı: "Adı Soyadı",
  t_rIsCalledBack: "Geri Arandı Mı",
  t_rCalledBackedStartTime: "Geri Aranma T.",
  t_rCalledBackedQueue: "Geri Arandığı Kuyruk",
  t_rUsername: "Temsilci",
  t_rInboundSuccess: "Cevaplanan",
  t_rInboundTotal: "Gelen Toplam",
  t_rTotal: "Genel Toplam",
  t_rLogin: "Giriş",
  t_rLogout: "Çıkış",
  t_rAvgCallTime: "Ort.Konuşma Süresi",
  t_rOnlineTime: "Login Süresi",
  t_rInCallTime: "Toplam Konuşma Süresi",
  t_rInboundSuccessSL: "SL Cevaplama",
  t_rInboundFail: "Kaçan",
  t_rOutboundSuccess: "Giden Başarılı",
  t_rOutboundFail: "Giden Başarısız",
  t_rTotalWaitTime: "Toplam Bekleme Süresi",
  t_rAvgWaitTime: "Ortalama Bekleme Süresi",
  t_rMaxWaitTime: "Max Bekleme Süresi",
  t_rAgentMissed: "Temsilci Kaçan",
  t_rInitMoment: "Tarih",
  t_rCount: "Adet",
  t_rDate: "Tarih",
  t_rCustomerID: "Müşteri No",
  t_rProductID: "Ürün No",
  t_rInboundSuccessRate: "Cevaplama Oranı",
  t_rDialerSuccess: "Oto.Arama Başarılı",
  t_rDialerFail: "Oto.Arama Başarısız",
  t_rDialerTotal: "Oto.Arama Toplam",
  t_rDialerTotalTalkTime: "Oto.Arama Top.Kon.Süresi",
  t_rDialerTotalIVRTime: "Oto.Arama Top.Ivr Süresi ",
  t_rDialerAvgIVRTime: "Oto.Arama Ort.Ivr Süresi",
  t_rDialerAvgTalkTime: "Oto.Arama Ort.Kon.Süresi",
  t_rTotalAvgCallTime: "Ortalama Çağrı Süresi",
  t_rTotalCallTime: "Top. Çağrı Süresi",
  t_rTotalSuccessCallCount: "Başarılı Çağrı Adedi",
  t_rTotalCallCount: "Top. Çağrı Adedi",
  t_rDialerTotalCallTime: "Oto. Arama Çağrı Süresi",
  t_rDialerAvgCallTime: "Oto. Arama Ort. Çağrı Süresi",
  t_rTotalSuccess: "Top.Görüşülen",
  t_rTotalTalkTime: "Top.Kon.Süresi",
  t_rTotalAvgTalkTime: "Ort.Kon.Süresi",
  t_rTotalCustomer: "Toplam Müşteri",
  t_rCallable: "Aranabilir",
  t_rUnCallable: "Tamamlanmış",
  t_rZeroAttempts: "Hiç Aranmamış",
  t_rStartDate: "Tarih",
  t_rAgentCount: "Temsilci Sayısı",
  t_rIvrMissed: "IVR Sonlanan Çağrı A.",
  t_rInboundAgentTotal: "Temsilciye Gelen Çağrı A.",
  t_rInboundSuccessSLA30: "30 Aşarak Karşılanan Çağrı A.",
  t_rInboundSuccessSL120: "120sn İçinde Karşılanan Çağrı A.",
  t_rInboundSuccessSLA120: "120 Aşarak Karşılanan Çağrı A.",
  t_rInboundFailSL30: "30sn İçinde Kaçan Çağrı A.",
  t_rInboundFailSLA30: "30sn Aşarak Kaçan Çağrı A.",
  t_rInboundSuccessSLA30AvgWaitTime: "30sn Aşarak Karşılanan Çağrı Ort.Bek.S.",
  t_rInboundSuccessSLA120AvgWaitTime: "120sn Aşarak Karşılanan Çağrı Ort.Bek.S.",
  t_rAvgWaitTimeSLA30: "30sn Aşarak Kaçan Çağrı Ort.Bek.S.",
  t_rSuccessRate: "Karşılama Oranı",
  t_rServiceLevel: "Hizmet Seviyesi",
  t_rTotalConnectedLength: "Toplam Görüşme Süresi ",
  t_rAvgConnectedLength: "Ortalama Görüşme Süresi",
  t_rTotalSuccessCount: "Toplam Görüşme Adedi",
  t_rBaseRemoteNumber: "Telefon",
  t_rTotalCall: "Toplam Çağrı",
  t_rSuccessCall: "Ulaşılan",
  t_rFailCall: "Ulaşılmayan/ Cevapsız",
  t_rAMD: "Telesekreter",
  t_rTotalCallLength: "Toplam Görüşme Süresi",
  t_rFirstCallTime: "İlk Arama Tarihi",
  t_rLastCallTime: "Son Arama Tarihi",
  t_rAbandon: "Kaçan",
  t_rSaleCall: "Satış Var Mı",
  t_rEventTime: "Tarih",
  t_rEvent: "Olay",
  t_rPrevStatus: "Durum",
  t_rPrevReason: "Sonuç",
  t_rPrevStatusLength: "Süre(sn)",
  t_rInboundSL30: "SL30",
  t_rInboundAnswerRate: "Cevaplama Oranı",
  t_rInboundAvgCallTime: "IB ACHT",
  t_rOutboundAvgCallTime: "OB ACHT",
  t_rInterval: "Saat",
  t_rInboundTotalCallTime: "IB Toplam Görüşme Süresi",
  t_rOutboundTotalCallTime: "OB Toplam Görüşme Süresi",
  t_rDayName: "Gün",
  t_rInboundSuccessSL30: "İlk 30sn Cevaplanan",
  t_rInboundSuccessSL30Rate: "İlk 30sn Cevaplama Oranı",
  t_rInboundFailSL10: "İlk 10sn Kaçan",
  t_rInboundSuccessRateSL30: "SL30 Cevaplama Oranı",
  t_rInboundFailRate: "Kaçan Çağrı Oranı",
  t_rInboundAvgTalkTime: "Gelen Ort.Konuşma Süresi",
  t_rInboundTotalTalkTime: "Gelen Top.Konuşma Süresi",
  t_rInboundAvgAnswerTime: "Ort.Cevaplama Süresi",
  t_rOutboundTotal: "Toplam Dış Arama",
  t_rOutboundAvgTalkTime: "Giden Ort.Konuşma Süresi",
  t_rOutboundTotalTalkTime: "Giden Top.Konuşma Süresi",
  t_rCreateDate: "Yüklenme Tarihi",
  t_rAssignedAgent: "Temsilci",
  t_rLine: "Hat",
  t_rSuccessCalls: "Ulaşılan",
  t_rFailCalls: "Ulaşılamayan",
  t_rHour: "Saat",
  t_rAgentSentimentPositiveSegmentCount: "Temsilci Pozitif Segment Sayısı",
  t_rAgentSentimentPositiveScore: "Temsilci Pozitif Skor",
  t_rAgentSentimentPositiveTokenCount: "Temsilci Pozitif Token Sayısı",
  t_rAgentSentimentNegativeSegmentCount: "Temsilci Negatif Segment Sayısı",
  t_rAgentSentimentNegativeScore: "Temsilci Negatif Skor",
  t_rAgentSentimentNegativeTokenCount: "Temsilci Negatif Token Sayısı",
  t_rAgentSentimentTotalScore: "Temsilci Toplam Skor",
  t_rAgentSentimentPositiveSegmentRate: "Temsilci Pozitif Segment Oranı(%)",
  t_rAgentSentimentNegativeSegmentRate: "Temsilci Negatif Segment Oranı(%)",
  t_rAgentSentimentNotrSegmentRate: "Temsilci Nötr Segment Oranı(%)",
  t_rCustomerSentimentPositiveSegmentCount: "Müşteri Pozitif Segment Sayısı",
  t_rCustomerSentimentPositiveScore: "Müşteri Pozitif Skor",
  t_rCustomerSentimentPositiveTokenCount: "Müşteri Pozitif Token Sayısı",
  t_rCustomerSentimentNegativeSegmentCount: "Müşteri Negatif Segment Sayısı",
  t_rCustomerSentimentNegativeScore: "Müşteri Negatif Skor",
  t_rCustomerSentimentNegativeTokenCount: "Müşteri NegatitToKen Sayısı",
  t_rCustomerSentimentTotalScore: "Müşteri Toplam Skor",
  t_rCustomerSentimentPositiveSegmentRate: "Müşteri Pozitif Segment Oranı(%)",
  t_rCustomerSentimentNegativeSegmentRate: "Müşteri Negatif Segment Oranı(%)",
  t_rCustomerSentimentNotrSegmentRate: "Müşteri Nötr Segment Oranı(%)",
  t_rTotalDuration: "Toplam Konuşma Süresi",
  t_rOverlappedSpeechRatio: "Üstüste Konuşma Oranı",
  t_rOverlappedSpeechDuration: "Üstüste Konuşma Süresi(sn)",
  t_rOverlappedSilenceRatio: "Sessizlik Oranı",
  t_rOverlappedSilenceDuration: "Sessizlik Süresi(sn)",
  t_rAgentSpeechRatio: "Temsilci Konuşma Oranı",
  t_rAgentSpeechDuration: "Temsilci Konuşma Süresi(sn)",
  t_rAgentSpeakingRate: "Temsilci Konuşma Hızı(Kelime / DaKıKa)",
  t_rAgentInterruptCount: "Temsilci Söz Kesme Sayısı",
  t_rCustomerSpeechRatio: "Müşteri Konuşma Oranı",
  t_rCustomerSpeechDuration: "Müşteri Konuşma Süresi(sn)",
  t_rCustomerSpeakingRate: "Müşteri Konuşma Hızı(Kelime / Dakika)",
  t_rCustomerInterruptCount: "Müşteri Söz Kesme Sayısı",
  t_rListens: "Dinlenen",
  t_rTotalEvaluations: "Değerlendirilen",
  t_rAutoEvaluations: "Otomatik Değerlendirilen",
  t_rEvaluations: "Manuel Değerlendirilen",
  t_rAvgAutoEvaluationsPoint: "Otomatik Değerlendirilen Ort.Puan",
  t_rAvgEvaluationsPoint: "Manuel Değerlendirilen Ort.Puan",
  t_rTotalCalls: "Toplam Çağrı",
  t_rOverlappedSilence: "Çift Yönlü Sessizlik Adet",
  t_rOverlappedSilenceRateByTotalCalls: "Çift Yönlü Sessizlik / Total Çağrı Adedi(%)",
  t_rOverlappedSilenceRateBySuccessCalls: "Çift Yönlü Sessizlik / Ulaşılan Çağrı Adedi(%)",
  t_rClosedByAgentCount: "MT Tarafından Sonlanan",
  t_rClosedByCustomerCount: "Müşteri Tarafından Sonlanan",
  t_rClosedByAgentRateByTotalCalls: "MT Tarafından Sonlanan(%)",
  t_rClosedByCustomerRateByTotalCalls: "Müşteri Tarafından Sonlanan(%)",
  t_rForbiddenWords: "Yasaklı Kelime Kullanımı",
  t_rForbiddenWordsRateByTotalCalls: "Yasaklı Kelime Kullanımı / Total Çağrı Adedi(%)",
  t_rForbiddenWordsRateBySuccessCalls: "Yasaklı Kelime Kullanımı / Ulaşılan Çağrı(%)",
  t_rAgentNoSpeechCount: "Müşterinin Konuşup MT nın Konuşmadığı Çağrı Adedi",
  t_rAgentNoSpeechRateByTotalCalls: "Müşterinin Konuşup MT nın Konuşmadığı Çağrı Adedi / Total Çağrı Adedi(%)",
  t_rAgentNoSpeechRateBySuccessCalls: "Müşterinin Konuşup MT nın Konuşmadığı Çağrı Adedi / Ulaşılan Çağrı Adedi(%)",
  t_rAvgAHT: "Ortalama AHT",
  t_rMaxAHT: "En Yüksek AHT ORT",
  t_rMinAHT: "En Düşük AHT ORT",
  t_rDuration: "Çağrı Süresi",
  t_rListeningDuration: "Dinleme Süresi",
  t_rPlayCount: "Başlatma Adet",
  t_rPauseCount: "Durdurma Adet",
  t_rIsMuted: "Ses Kapandı Mı ?",
  t_rPlayBackChangeCount: "Hız Değiştirme Adet",
  t_rPlayBackStartSpeed: "Başlangıç Hızı",
  t_rPlayBackEndSpeed: "Bitiş Hızı",
  t_rManualForm: "Manuel Form",
  t_rManualFormPoint: "Manuel Form Puan",
  t_rAutoForm: "Otomatik Form",
  t_rAutoFormPoint: "Otomatik Form Puan",
  t_rQualityNote: "Kalite Notu",
  t_abandonedCampaign: "Kaçan Çağrı Kampanyası",
  t_autoRecycle: "Otomatik Döngü",
  t_goalListByAgents: "Temsilci Hedef Listesi",
  t_addGoal: "Hedef Ekle",
  t_addCollection: "Hareket Ekle",
  t_collections: "Hareketler",
  t_archivedMessages: "Arşivlenmiş Mesajlar",
  t_archivetedMessages: "Arşivlenmiş Mesajlar",
  // Report Names 
  t_rQualityDetails: "Kalite Detayı",
  t_rInboundCallDetails: "Gelen Çağrı Özeti",
  t_rCallTimeAndCounts: "Çağrı Süresi ve Adetleri",
  t_rCallDetails: "Çağrı Detayı",
  t_rClassificationDetails: "Sınıflandırma Detayı",
  t_rAgentPerformanceSummary: "Temsilci Performans Analizi",
  t_rAgentPerformanceSummaryByShift: "Temsilci Vardiya Performansı",
  t_rVerdictDetails: "Sonuç Kodu Detayı",
  t_rSmsHistory: 'SMS Geçmişi',
  t_rMailHistory: 'Mail Geçmişi',
  t_rSecondLineDetails: 'İkinci Hat / Dahili Detayı',
  t_rAbandonCalls: 'Kaçan Çağrı Detayı',
  t_rMissedCalls: 'Cevaplanmayan Çağrı Detayı',
  t_rAgentSummary: 'Temsilci Özet',
  t_rQueueSL: 'SL / Kuyruk',
  t_rOutOfWork: 'Mesai Dışı Gelenler',
  t_rQueueDaily: 'Gelen Çağrı Adetleri / Gün',
  t_rAgentDaily: 'Gelen Çağrı Adetleri / Gün',
  t_rQueueHourly: 'Gelen Çağrı Adetleri / Saat',
  t_rAgentHourly: 'Gelen Çağrı Adetleri / Saat',
  t_rVerdictByDate: 'Sonuç Kodu Adetleri',
  t_rVerdictByDaily: 'Sonuç Kodu / Gün',
  t_rVerdictByQueue: 'Sonuç Kodu / Kuyruk',
  t_rVerdictByAgent: 'Sonuç Kodu / Agent',
  t_rClassificationByDate: 'Sınıflandırma Adetleri',
  t_rClassificationByDaily: 'Sınıflandırma / Gün',
  t_rClassificationByQueue: 'Sınıflandırma / Kuyruk',
  t_rClassificationByAgent: 'Sınıflandırma / Agent',
  t_rCallCountByDate: 'Çağrı Adetleri / Gün',
  t_rDataSummaryByQueue: 'Veri Özeti / Kuyruk',
  t_rProductActionDetails: "Ürün Aksiyon Detayları",
  t_rLoginLogout: 'Giriş / Çıkış',
  t_rLocalNumberSL: 'SL / Numara',
  t_rCallTimeAndCountByDirection: 'Çağrı Süresi ve Adetleri / Yön',
  t_rPozitifCallDetails: 'Çağrı Detayları / Havuz',
  t_rActivity: 'Temsilci Aktivite',
  t_rDailyCallValues: 'Çağrı Değerleri / Gün',
  t_callrecords: "Çağrı Kayıtları",
  t_rHourlyCallValues: 'Çağrı Değerleri / Saat',
  t_rAgentPerformanceDaily: 'Temsilci Performans / Gün',
  t_rAgentPerformanceMonthly: 'Temsilci Performans / Ay',
  t_rAgentPerformanceHourly: 'Temsilci Performans / Saat',
  t_rMonthlyCallValues: 'Çağrı Değerleri / Ay',
  t_rCallPerformanceByDate: 'Çağrı Performans / Gün',
  t_rDataSummaryByCreateDate: 'Veri Özeti / Yüklenme Tarihi',
  t_rDataSummaryByAssignedAgent: 'Veri Özeti / Atama',
  t_rDataSummaryByDynamicFields: 'Veri Özeti / Dinamik Kırılım',
  t_rLinePerformance: 'Hat Performans',
  t_rInboundCallValuesByHourly: 'Gelen Çağrı Değerleri / Saat',
  t_rSurveyResult: 'Anket Sonuçları',
  t_rEmotionAnalysis: 'Duygu Analizi / Gün',
  t_rSpeechStatistics: 'Konuşma İstatistikleri / Gün',
  t_analysis: "Analiz",
  t_rQualityByDate: 'Kalite / Gün',
  t_rQualityByAgent: 'Kalite / Agent',
  t_rCallStatisticsByDate: 'Çağrı İstatistikleri / Gün',
  t_rListeningDetails: 'Dinleme Detayları',
  t_rProject: 'Proje',
  t_rIVR: 'IVR',
  t_rInQueue: "Kuyrukta",
  t_rInProgress: "İşlemde",
  t_rShiftStart: 'Vardiya Başlangıç',
  t_rShiftEnd: 'Vardiya Bitiş',
  t_rShiftDuration: 'Vardiya Süresi',
  t_rMissingTime: 'Kayıp Zaman',
  t_rCalculatedShiftDuration: 'Hesaplanan Vardiya Süresi',
  t_rCalculatedBreak: 'Hesaplanan Mola Süresi',
  t_rTotalWorkDuration: 'Toplam Çalışma Süresi',
  t_rLeave: 'İzin',
  t_rBreakTime: 'Referans Mola Süresi',
  t_rCalculatedWorkTime: 'Hesaplanan Çalışma Süresi',
  t_rWorkDuration: 'Çalışma Süresi',
  t_rTotalWorkDifference: 'Çalışma Süresi Farkı',
  t_reportName: "Rapor Adı",
  t_dataCount: "Veri Adedi",
  t_expand: "Genişlet",
  t_shrink: "Daralt",
  t_darkMode: "Gece Modu",
  t_lightMode: "Gündüz Modu",
  t_alertCategory: "Uyarı Kategorisi",
  t_targetCategory: "Hedef Kategorisi",
  t_targetType: "Hedef Tipi",
  t_none: "Yok",
  t_seeExampleTimePatterns: "Örnek Zamanlamaları Gör",
  t_apply: "Uygula",
  t_callList: 'Atama Listesi',
  t_pool: 'Havuz',
  t_all: 'Tümü',
  t_logs: 'Loglar',
  t_totalAttempts: 'Toplam Temas',
  t_insertDate: 'Son Arama',
  t_actionTime: 'Aranacak Z',
  t_assignDate: 'Atama T',
  t_createDate: 'Yüklenme T',
  t_createTime: 'Yüklenme Z',
  t_exAgent: 'Eski Temsilci',
  t_addExtraCustomerInfo: 'Ekstra Müşteri Bilgisi Ekle',
  t_primaryKey: 'Birincil Kırılım',
  t_secondaryKey: 'İkincil Kırılım',
  t_qVoice: 'Sesli',
  t_qVideo: 'Görüntülü',
  t_qText: 'Yazılı',
  t_interactionWeight: 'Kanal Ağırlığı',
  t_records: 'Kayıtlar',
  t_qualityForm: 'Kalite Formu',
  t_keyWords: 'Anahtar Kelimeler',
  t_criterions: 'Kriterler',
  t_required: "Zorunlu",
  t_isChatFinishCode: 'Yazılı Kanal Sonuç Kodu',
  t_minWaitInQueue: 'Min. Kuyrukta Bekleme Süresi (sn)',
  t_maxWaitInQueue: 'Max. Kuyrukta Bekleme Süresi (sn)',
  t_minDuration: 'Min. Görüşme Süresi (sn)',
  t_maxDuration: 'Max. Görüşme Süresi (sn)',
  t_rIsEvaluated: 'Değerlendirme',
  t_rEvaluator: 'Değerlendiren',
  t_rEvaluatedStartTime: 'Değerlendirme Başlangıç T.',
  t_rEvaluatedEndTime: 'Değerlendirme Bitiş T.',
  t_rMinQualityPoint: 'Min. Kalite Puanı',
  t_rMaxQualityPoint: 'Max. Kalite Puanı',
  t_rQualityForm: 'Kalite Formu',
  t_rIsObjection: 'İtiraz',
  t_multiple: 'Çoklu Seçim',
  t_question: 'Soru',
  t_minValue: 'Min. Değer',
  t_maxValue: 'Max. Değer',
  t_minTrueCount: 'Min. Doğru Sayısı',
  t_ordered: 'Sıralı',
  t_minPoint: 'Min. Puan',
  t_maxPoint: 'Max. Puan',
  t_point: 'Puan',
  t_selected: 'Seçili',
  t_punish: 'Cezalandır',
  t_pointRange: 'Puan Aralığı',
  t_answer2: 'Cevap',
  t_openInNewWindow: 'Yeni Pencerede Aç',
  t_playbackRate: 'Oynatma Hızı',
  t_isMailSendToAgent: 'Temsilciye Mail At',
  t_httpRequest: 'HTTP İsteği',
  t_tts: 'TTS',
  t_stt: 'STT',
  t_beepTone: "Beep Ton",
  t_model: 'Model',
  t_whatsapp: 'Whatsapp',
  t_readQR: 'QR Okut',
  t_url: 'Bağlantı Adresi (URL)',
  t_headers: 'Headers (JSON)',
  t_body: 'Body (JSON)',
  t_text: 'Metin',
  t_useableVariables: 'Kullanılabilir Değişkenler',
  t_remoteNumber: 'Telefon Numarası',
  t_hideVariableFromAgent: 'Değişkeni Temsilciden Gizle',
  t_provider: 'Sağlayıcı',
  t_speaker: 'Konuşmacı',
  t_isInList: 'Listede Olan',
  t_isNotInList: 'Listede Olmayan',
  t_isContains: 'İçeren',
  t_isNotContains: 'İçermeyen',
  t_IsConvergent: 'Ortaklık Var',
  t_IsNotConvrgent: 'Ortaklık Yok',
  t_changeAllQueues: 'Toplu Kuyruk Değişimi',
  t_remove: 'Çıkar',
  t_answerToObject: 'İtirazı Cevapla',
  t_objectToForm: 'Forma İtiraz Et',
  t_objections: 'İtirazlar',
  t_accept: 'Kabul Et',
  t_rLastObjectingUser: 'Son İtiraz Eden',
  t_evaluatedDaily: "Bügün Değerlendilen",
  t_avgPointDaily: "Bügün Ort. Puan",
  t_evaluatedWeekly: "Bu Hafta Değerlendilen",
  t_avgPointWeekly: "Bu Hafta Ort. Puan",
  t_evaluatedMonthly: "Bu Ay Değerlendilen",
  t_avgPointMonthly: "Bu Ay Ort. Puan",
  t_standart: 'Standart',
  t_advanced: 'Gelişmiş',
  t_quality: 'Kalite',
  t_removeEvaluation: 'Değerlendirmeyi Sil',
  t_openOnNewWindow: 'Yeni Sekmede Aç',
  t_undefined: 'Tanımsız',
  t_callTime: 'Çağrı Süresi',
  t_serviceLevel: 'Servis Seviyesi',
  t_abandonRate: 'Kaçan Çağrı Oranı',
  t_callAnswerTime: 'Çağrı Cevaplama Hızı',
  t_noRouteToDestination: 'Hedef Yok',
  t_passwordSentToMailAdress: 'Şifreniz mail adresinize gönderildi.',
  t_noEmailAdress: 'Hesabınızın e-posta adresi bulunmamaktadır. Lütfen yöneticinizle iletişime geçin.',
  t_forgotPassword: 'Şifremi Unuttum',
  t_saveRecord: 'Ses Kaydı Al',
  t_isHttps: 'HTTPS',
  t_callStatistics: 'Çağrı İstatistikleri',
  t_addtCategory: 'Yeni Kategori Ekle',
  t_create: 'Oluştur',
  t_questionCount: 'Soru Adet',
  t_minPts: 'Min Puan',
  t_maxPts: 'Max Puan',
  t_questionsAndAnswers: 'Sorular & Cevaplar',
  t_extensionRoutes: 'Rotalar',
  t_evaluatedRecords: 'Değerlendirilmiş Kayıtlar',
  t_alerts: 'Uyarılar',
  t_audioEncoding: "Audio Encoding",
  t_gender: "Ses Cisiyeti",
  t_token: "Token",
  t_showTCKN: "TCKN Göster",
  t_webChat: "Web Chat",
  t_whatsapp: "Whatsapp",
  t_instagram: "Instagram",
  t_messenger: "Messenger",
  t_informAgent: 'Temsilciyi Bilgilendir',
  t_autoCustomerCard: 'Otomatik Kart Açma',
  t_downloadCallRecordings: 'Ses Kaydı İndirme',
  t_outboundCallApproval: 'Çağrı Onaylama',
  t_callHistoryDownloadRecording: 'Çağrı Geçmişi Ses Kaydı İndirme',
  t_callHistoryListenRecording: 'Çağrı Geçmişi Ses Kaydı Dinleme',
  t_dialerReopenTotalAttempts: 'Dialer Toplam Temas',
  t_dialerReopenDailyAttempts: 'Dialer Günlük Temas',
  t_dialerClearCallSchedules: 'Dialer Zamanı Gelmeyenler',
  t_showAllFinishCodes: 'Tüm Sonuç Kodları Göster',
  t_finishcodesofcallsfortoday: "Bugün gerçekleşen çağrıların sonuç kodları",
  t_updateLastFinishCode: 'Son Sonuç Kodu Güncelleme',
  t_showIVRInputs: 'IVR Tuşlamalarını Görüntüle',
  t_hidePhoneNumbers: 'Telefon Numaralarını Gizle',
  t_addExtraData: 'Ekstra Data Ekleme',
  t_script: 'Script',
  t_crmCall: 'Arama',
  t_crmAddCustomer: 'Müşteri Ekleme',
  t_displayAllRecords: 'Tüm Dosyaları Görüntüleme',
  t_editCustomer: 'Müşteri Güncelleme',
  t_crmlistenRecording: 'CRM Ses Kaydı Dinleme',
  t_showNoteDetails: 'Not Detayını Göster',
  t_excelCustomer: 'Excel Müşteri',
  t_excelCustomerByPhone: 'Excel Telefon',
  t_excelCustomerByProduct: 'Excel Ürün',
  t_showPhoneDetails: 'Telefon Detayını Göster',
  t_justShowOwnCustomers: 'Sadece Kendi Müşterileri Göster',
  t_dontSendCustomMessage: 'Mesaj Metni Değiştiremesin',
  t_keywordCategories: "Anahtar Kelime Kategorisi",
  t_keyWord: "Anahtar Kelime",
  t_minOccurrence: "En Az Bulunma",
  t_maxOccurrence: "En Çok Bulunma",
  t_inFirstSeconds: "İlk Saniyelerde",
  t_inLastSeconds: "Son Saniyelerde",
  t_logicCondition: "Mantıksal Koşul",
  t_reverse: "Tersi",
  t_and: "Ve",
  t_or: "Veya",
  t_customer: "Müşteri",
  t_oneOfBoth: "İkisinden Biri",
  t_bothOfThem: "İkiside",
  t_liveDashboards: 'CANLI EKRANLAR',
  t_tools: 'ARAÇLAR',
  t_tools2: 'Araçlar',
  t_admin: 'YÖNETİCİ',
  t_useDatesForEvaluateDate: 'Tarih filtresini, değerlendirme tarihi olarak kullan',
  t_menuLineGroups: 'Menü - Hat Grubu',
  t_menuDialPlans: 'Menü - Arama Planı',
  t_menuIVR: 'Menü - IVR',
  t_menuAnnouncements: 'Menü - Anonslar',
  t_menuRoutes: 'Menü - Yönlendirmeler',
  t_menuTimeCondition: 'Menü - Zaman Koşulu',
  t_menuUsers: 'Menü - Kullanıcılar',
  t_menutExtensionRoutes: 'Menü - Rotalar',
  t_menuQueues: 'Menü - Kuyruklar',
  t_menuProjects: 'Menü - Projeler',
  t_menutStatuses: 'Menü - Durumlar',
  t_menutFinishCodes: 'Menü - Sonuç Kodları',
  t_menuBlackList: 'Menü - Kara Liste',
  t_menuShifts: 'Menü - Vardiyalar',
  t_menuProfiles: 'Menü - Profiller',
  t_menuRecordingExport: 'Menü - Ses Export',
  t_menuSms: 'Menü - SMS',
  t_menuReports: 'Menü - Raporlar',
  t_menuGoalManagement: 'Menü - Hedef Yönetimi',
  t_menuDialer: 'Menü - Otomatik Arama',
  t_menuLiveDashboard: 'Menü - Canlı İzleme',
  t_menuPhoneBook: 'Menü - Rehber',
  t_menuCalendar: 'Menü - Takvim',
  t_menuCrm: 'Menü - CRM',
  t_menuDashboard: 'Menü - Pano',
  t_menuEvaluatedRecords: 'Menü - Değerlendirilmiş Kayıtlar',
  t_menuSystemManagement: 'Menü - Sistem Yönetimi',
  t_menuOperationManagement: 'Menü - Operasyon Yönetimi',
  t_menuIntegrations: 'Menü - Entegrasyonlar',
  t_menuSettings: 'Menü - Ayarlar',
  t_menuWallboard: 'Menü - Wallboard',
  t_menuDashboard: 'Menü - Dashboard',
  t_menuQueueGroup: 'Menü - Kuyruk Grubu',
  t_menuvIVR: 'Menü - Değişkenli IVR',
  t_menuScheduler: 'Menü - Planlayıcı',
  t_menuExternalChat: 'Menü - Yazılı Kanal',
  t_menuSurvey: 'Menü - Anket',
  t_menuEMail: 'Menü - E-Mail',
  t_menuAlerts: 'Menü - Uyarılar',
  t_menuQuality: 'Menü - Kalite',
  t_otherAgentList: 'Diğer - Temsilci Listesi',
  t_othertAutoCustomerCard: 'Diğer - Otomatik Kart Açma',
  t_otherDownloadCallRecordings: 'Diğer - Ses Kaydı İndirme',
  t_otherOutboundCallApproval: 'Diğer - Çağrı Onaylama',
  t_otherCallHistoryDownloadRecording: 'Diğer - Çağrı Geçmişi Ses Kaydı İndirme',
  t_otherCallHistoryListenRecording: 'Diğer - Çağrı Geçmişi Ses Kaydı Dinleme',
  t_otherDialerReopenTotalAttempts: 'Diğer - Dialer Toplam Temastan Kapananları Açma',
  t_otherDialerReopenDailyAttempts: 'Diğer - Dialer Günlük Temastan Kapananları Açma',
  t_otherDialerClearCallSchedules: 'Diğer - Dialer Zamanı Gelmeyenleri Açma',
  t_otherShowAllFinishCodes: 'Diğer - Tüm Sonuç Kodları Göster',
  t_otherUpdateLastFinishCode: 'Diğer - Son Sonuç Kodu Güncelleme',
  t_otherShowIVRInputs: 'Diğer - IVR Tuşlamalarını Görüntüle',
  t_otherHidePhoneNumbers: 'Diğer - Telefon Numaralarını Gizle',
  t_ChatSouffle: 'Chat - Sufle',
  t_chatChat: 'Chat - Mesajlaşma',
  t_chatAnnounce: 'Chat - Duyuru',
  t_crmFilters: 'Crm - Filtreler',
  t_crmColumns: 'Crm - Başlıklar',
  t_crmManagement: 'Crm - Yönetim',
  t_crmAddExtraData: 'Crm - Ekstra Data Ekleme',
  t_crmScript: 'Crm - Script',
  t_crmProducts: 'Crm - Ürünler',
  t_crmPhones: 'Crm - Telefonlar',
  t_crmNotes: 'Crm - Notlar',
  t_crmSms: 'Crm - SMS',
  t_crmSmsSending: 'Crm - SMS Gönderim',
  t_crmCrmCall: 'Crm - Arama',
  t_crmCrmAddCustomer: 'Crm - Müşteri Ekleme',
  t_crmDisplayAllRecords: 'Crm - Tüm Dosyaları Görüntüleme',
  t_crmEditCustomer: 'Crm - Müşteri Güncelleme',
  t_crmCrmlistenRecording: 'CRM - CRM Ses Kaydı Dinleme',
  t_crmShowNoteDetails: 'Crm - Not Detayını Göster',
  t_crmExcelCustomer: 'Crm - Excel Müşteri Bazlı',
  t_crmExcelCustomerByPhone: 'Crm - Excel Telefon Bazlı',
  t_crmShowPhoneDetails: 'Crm - Telefon Detayını Göster',
  t_rTransferType: "Transfer Tipi",
  t_rTransferTarget: "Transfer Hedefi",
  t_acw: "ACW Süresi",
  t_rDialerConnectedToAgent: "Oto. Arama Bağlanan",
  t_rDialerAbandon: "Oto. Arama Kaçan",
  t_rACWLength: "ACW Süresi",
  t_callAnalize: "Çağrı Analizi",
  t_callFinishCode: "Çağrı",
  t_writtenChannelFinishCode: "Yazılı Kanal",
  t_productFinishCode: "Ürün",
  t_finishCodeType: "Sonuç Kodu Tipi",
  t_speechText: "Konuşma Metni",
  t_reportGroup: "Gruplama",
  t_pleaseSelect: "Seçim Yapınız",
  t_awaitingInteractionsCount: "Bekleyen",
  t_descawaitingInteractionsCount: "Seçili kuyruklarda anlık bekleyen çağrı toplamı",
  t_answeredCount: "Cevaplanan",
  t_descansweredCount: "Seçili kuyruklarda toplam cevaplanan çağrı sayısı",
  t_abandonedCount: "Kaçan",
  t_descabandonedCount: "Seçili kuyruklarda toplam kaçan çağrı sayısı",
  t_activeInteractionsCount: "Anlık Çağrı Sayısı",
  t_descactiveInteractionsCount: "Seçili kuyruklarda toplam anlık çağrı sayısı",
  t_averageWaitTime: "Ortalama Bekletme Süresi",
  t_descaverageWaitTime: "Seçili kuyruklarda gelen çağrıların kuyrukta bekeme süreslerinin ortalaması",
  t_callBackSuccess: "Başarılı Geri Arama",
  t_desccallBackSuccess: "Kaçan çağrılara yapılan geri dönüşlerin sayısı",
  t_firstCallResolutionCount: "İlk Çağrıda Çözüm",
  t_descfirstCallResolutionCount: "Sadece bir kere görüşülmüş olup, başarılı kategorisinde oluşturulmuş sonuç kodları ile kapanan çağrıların sayısı",
  t_serviceLevel: "Servis Seviyesi",
  t_descserviceLevel: "Kuyruk ayarlarında belirlenen servis seviyeleri içerisinde, cevaplanan çağrı sayılarının tüm gelen çağrılara oranı",
  t_sRLevel: "Başarı Oranı",
  t_descsRLevel: "Başarılı olan sonuç kodları ile tamamlanan çağrıların tüm çağrılara oranı",
  t_averageConnectedTime: "Ortalama Çağrı Süresi",
  t_descaverageConnectedTime: "Tüm görüşülen çağrıların ortalama süresi",
  t_abandonRate: "Kaçan Çağrı Oranı",
  t_descabandonRate: "Seçili kuyruklarda gelen ve cevaplanmayan çağrıların tüm çağrılara oranı",
  t_transferQueue: "Kuyruk Aktar",
  t_rAvgQueueLength: "Ort. Kuyrukta Bekleme Süresi",
  t_crmlistenRecordingJustOwn: "Sadece Kendi Çağrılarını Dinle",
  t_deleteQualityForm: "Kalite Formunu Sil",
  t_currentPasswordDoesNotMatch: "Mevcut Parola Yanlış",
  t_confirmPasswordDoesNotMatch: "Onaylanan Parola Eşleşmiyor",
  t_rRinging: "Çalıyor",
  t_rEnterIVR: "IVR Yönlendirildi",
  t_ranswer: "IVR Cevapladı",
  t_rStartIvr: "IVR Başladı",
  t_rTimeConditionFail: "Zaman Koşulu Sağlanmadı",
  t_rTimeConditionSuccess: "Zaman Koşulu Sağlandı",
  t_rStartIvrMenu: "Menü",
  t_rMenuAttempt: "Temas",
  t_rMenuAnnouncement: "Anons",
  t_rDtmfResult: "Tuşlama Sonucu",
  t_rTaskmenu: "Menü",
  t_rTasksurvey: "Anket İşlemi",
  t_rStartPreSurveyTask: "Anket Başlangıcı",
  t_rPreSurveyAttempt: "Temas",
  t_rPreSurveyAnnouncement: "Anket Anonsu",
  t_rPreSurveyResults: "Anket Sonucu",
  t_rTaskcondition: "Koşul",
  t_rConditionFail: "Koşul Sağlanmadı",
  t_rTaskvariable: "Değişken",
  t_rStartVariableTask: " ",
  t_rTaskannounce: "Anons",
  t_rVariableAttempt: "Temas",
  t_rVariableAnnouncement: "Değişken Anonsu",
  t_rTaskhttp: "HTTP İsteği Yapıldı",
  t_rVariableFailed: "Değişken Hatası",
  t_rFailedTaskvariable: "Hatalı Değişken İşlemi",
  t_rMenuFailed: "Menü Hatası",
  t_rFailedTaskmenu: "Hatalı Menü İşlemi",
  t_rConditionError: "Koşul Hatası",
  t_rFailedTaskcondition: "Hatalı Koşul İşlemi",
  t_rTimeConditionError: "Zaman Koşulu Hatası",
  t_rIvrFailed: "IVR Sonlandırıldı",
  t_rFailedInboundCall: "Arama Sonlandırıldı",
  t_rFinalized: "Tamamlandı",
  t_rJoinedQueue: "Kuyruğa Girdi",
  t_rAssigned: "Atama Yapıldı",
  t_rAccepted: "Kabul Edildi",
  t_rPickedUp: "Açıldı",
  t_rRemoteFailed: "Karşı Numara Hatalı",
  t_rConditionSuccess: "Koşul Sağlandı",
  t_rTaskannounce: "Anons",
  t_rTaskqueue: "Kuyruk",
  t_roriginate: "Arama",
  t_rcall_target: "Çağrı Hedefi",
  t_rnew_bridge: "Yeni Kanal",
  t_rbridge_created: "Kanal Oluşturuldu",
  t_rcalls_bridged: "Kanallar Bağlandı",
  t_rcall_transferred: "Transfer",
  t_rUnassigned: "Atama Geri Alındı",
  t_rAbandoned: "Kaçan",
  t_rRejected: "Reddedildi",
  t_rNoAnswer: "Cevaplanmadı",
  t_rFailedTaskannounce: " ",
  t_rTransferAssigned: "Transfer için Atandı",
  t_rTransferAccepted: "Transfer Kabul Edildi",
  t_rnull: " ",
  t_rTaskivr: "IVR",
  t_rTasktransfer: "Transfer",
  t_rEndCall: "Çağrı Kapatıldı",
  t_rFailedTaskhttp: "Http İşlem Hatası",
  t_rTotalLocal: "Toplam Lokal Kapatma",
  t_rInboundLocal: "Gelen Aramalar Lokal Kapatma",
  t_rOutboundLocal: "Giden Aramalar Lokal Kapatma",
  t_rAvgDialingTime: "Ortalama Çaldırma	Süresi",
  t_rTotalInCallTime: "Toplam Çağrıda Süresi",
  t_rTotalStatusTime: "Toplam Durum Süresi",
  t_rTotalHoldCount: "Toplam Bekletme Sayısı",
  t_rTotalHoldTime: "Toplam Bekletme Süresi",
  t_rAvgHandleTime: "Ortalama Handle Süresi",
  t_rLogoutCount: "Logout Sayısı",
  t_rProductivityTime: "Verimlilik Süresi",
  t_rBreakCategoryTime: "Kullanılan Mola Süresi",
  t_rAvgAlertTime: "Ortalama Çalma Süresi",
  t_addFile: "Dosya Ekle",
  t_appointment: "Randevu",
  t_appointments: "Randevular",
  t_second: "Saniye",
  t_notAnsweringCallDesc2: "1 dakikadır Not Ansering durumundasınız, lütfen devam etmek için Available durumuna geçiniz.",
  t_notAnsweringCallDesc: "Çağrıya cevap vermediniz, devam etmek için Available durumuna geçiniz.",
  t_notAnsweringCall: "Cevap Verilmeyen Çağrı",
  t_timeFormat: "Zaman Formatı",
  t_callGroup: "Arama Grubu",
  t_pickupGroup: "Cevaplama Grubu",
  t_fodlerType: "Kalsörleme Formatı",
  t_conferenceCallAccepted: "Çağrı Bağlandı",
  t_conferenceCallHangup: "Çağrı Sonlandırıldı",
  t_conferenceCallDeclined: "Çağrı Bağlanamadı",
  t_conferenceCalling: "Arama Yapılıyor",
  t_agentListDetails: "Temsilci Listesi Detay",
  t_rAgentGroup: "Temsilci Grubu",
  t_new: "Yeni",
  t_recordsActivityLog: "Kayıt Dinleme Log",
  t_isAutoForm: "Otomatik Form",
  t_timestamp: 'Zaman Damgası',
  t_verificationCode: "Doğrulama Kodu",
  t_verificationCodeDesc: "Lütfen 6 haneli doğrulama kodunu giriniz",
  t_verify: "Onayla",
  t_phone: "Telefon",
  t_downloadVoyceVoiceConverter: "Voyce Ses Dönüştürücü İndir",
  t_position: "Pozisyon",
  t_ivrBreakOut: "Kuyruk Çıkışı",
  t_positionAndIvrBreakOut: "Pozisyon ve Kuyruk Çıkışı",
  t_ivrBreakOutTarget: "Kuyruk Çıkış Hedefi",
  t_displayAllCalendar: "Tüm Tavimleri Görüntüle",
  t_appointmentAssignmentDesc: "Adınıza yeni randevular atanmıştır. Lütfen takvim kontrolü yapınız.",
  t_appointmentAssignment: "Randevu Ataması",
  t_displayCalls: 'Çağrı İzleme',
  t_showUsedAreas: "Kullanılan Yerler",
  t_ftp: "FTP",
  t_voiceExport: "Ses Export",
  t_putFtp: "Ftp Gönder",
  t_remotePath: "Uzak Dosya Yolu",
  t_passive: 'Pasif',
  t_passives: 'Pasifler',
  t_state: 'Durum',
  t_frequentlyAskedQuestions: 'Sıkça Sorulan Sorular',
  t_helpCenter: 'Yardım Merkezi',
  t_contactUs: 'Bize Ulaşın',
  t_isSecure: 'Güvenli Bağlantı',
  t_rejectUnauthorized: 'Yetkisiz Olanı Reddet',
  t_customMetaData: 'Meta Data Özelleştir',
  t_metaDataEndpoint: 'Meta Data Endpoint',
  t_metaDataRemotePath: 'Meta Data Uzak Dosya Yolu',
  t_putAsFolder: 'Klasör Olarak Gönder',
  t_isDefault: 'Varsayılan',
  t_rSLDuration: 'SL Süresi',
  t_twoFactorAuthentication: "İki Aşamalı Doğrulama",
  t_sentToPhoneNumber: "telefon numarasına gönderilen",
  t_sentToEmailAdress: "mail adresine gönderilen",
  t_verifyYourIdentity: "doğrulama kodunu girerek, kimliğinizi doğrulayın",
  t_remainingTime: "Kalan Süre",
  t_sec: "sn",
  t_donthaveAccess: "Bu iletişim hesabına erişiminiz yok mu?",
  t_verificationCode: "Doğrulama kodunu",
  t_sendAsMail: "mail ile gönder.",
  t_sendAsSms: "sms ile gönder",
  t_verify: "Doğrula",
  t_closeProduct: "Ürün Kapama",
  t_setPassiveWhenNoProduct: 'Ürünü olmayan kayıtları, pasife çek',
  t_sendHttpRequest: "Http",
  t_month2: "Ay (Sıfırsız)",
  t_day2: "Gün (Sıfırsız)",
  t_shifts: "Vardiyalar",
  t_shift: "Vardiya",
  t_missingTime: 'Kayıp Zaman',
  t_isAnnualLeave: 'Yıllık İzin',
  t_paidLeave: 'Ücretli İzin',
  t_unpaidLeave: 'Ücretsiz İzin',
  t_isOwner: 'Kendisi',
  t_owner: 'Sahibi',
  t_thirdParty: 'Üçüncü Şahıs',
  t_breakTimeFraud: 'Mola Aşımı',
  t_dontControlLastTenDigit: 'Sağdan 10 hane kontrolü yapma',
  t_bank: 'Banka',
  t_dontChangeSurvey: 'Anket Durumunu Değiştirme',
  t_finishCodeBreakdown: 'Sonuç Kodu Kırılımı',
  t_displayPassiveRecords: 'Pasif Kayıtları Gör',
  t_newAssets: "Yeni Varlık",
  t_reached: "Kendisi",
  t_reachedDate: "Kendisi Tarihi",
  t_dontGetNoQueueRecords: "Kuyruğu Olmayan Kayıtları Getirme",
  t_agentHaveStaticQueueButDontHaveProjectPermission: 'Temsilcinin statik kuyruğu var ama proje izni yok.',
  t_autoRecall: 'Tekrar Ara',
  t_autoRecallDay: 'Gün Sonra',
  t_rDateName: "Gün",
  t_rWeekOfMonth: "Ayın Haftası",
  t_rGivenBreakTime: "Mola Hakkı",
  t_rOfflineTime: "Offline Süresi",
  t_rMonth: "Ay",
  t_rBreakTimeDifference: "Mola Farkı",
  t_copyFromTheEditor: "Editörden Kopyala",
  t_pasteIntoTheEditor: "Editöre Yapıştır",
  t_duplicate: "Çoğalt",
  t_identifier: "TCKN",
  t_undeafen: 'Sesi Kapat',
  t_deafen: 'Sesi Aç',
  t_canTransfer: 'Transfer Edilebilir',
  t_wordDistance: 'Kelime Uzaklığı',
  t_segmentDistance: 'Segment Uzaklığı',
  t_maxWordGroupCount: 'Max. Kelime Grubu',
  t_trendAnalyze: 'Trend Analizi',
  t_realTimeTranscript: 'Gerçek Zamanlı Transkript',
  t_snapshots: "Snapshotlar",
  t_snapshotDate: "Snapshot Tarihi",
  t_firstCallDate: "İlk Çağrı Tarihi",
  t_lastCallDate: "Son Çağrı Tarihi",
  t_callCount: "Çağrı Sayısı",
  t_density: "Yoğunluk",
  t_avarageDensity: "Ortalama Yoğunluk",
  t_displayDensityStatistics: 'Yoğunluk Grafiğini Göster',
  t_hideFromAgent: 'Temsilciden Gizle',
  t_customerNo: "Müşteri No",
  t_customerName: "Adı Soyadı",
  t_customerIdentifier: "TCKN",
  t_reached: "Ulaşım",
  t_reachedDate: "Ulaşım Tarihi",
  t_productNo: "Ürün No",
  t_artificialIntelligence: "Yapay Zeka",
  t_speakers: "Konuşmacı",
  t_oneTime: "Tek Sefer",
  t_scheduled: "Planlı",
  t_automatic: "Otomatik",
  t_periodTime: "Periyot Zamanı",
  t_prompt: "Prompt",
  t_aiCallResult: "AI Çağrı Sonucu",
  t_exceptions: "İstisnalar",
  t_rSendingResult: "Gönderim Sonucu",
  t_rReachRate: "Ulaşım Oranı ",
  t_rReachedCustomerCount: "Görüşülen Müşteri",
  t_rProcessedRate: "Tarama Oranı",
  t_rCalledCustomerCount: "Aranan Müşteri",
  t_rCustomerCount: "Toplam Müşteri",
  t_rCompany: "Firma",
  t_rCustomerDataAnalysis: "Müşteri Tarama Analizi",
  t_crmDisplayAllLogs: "Tüm Logları Görüntüle",
  t_aiCallAnalize: "AI Çağrı Analizi",
  t_aiCallEvaluation: "AI Çağrı Değerlendirme",
  t_AIEvaluations: "AI",
  t_redirectTheCallToSelf: "Çağrıyı Kendine Yönlendir",
  t_hangupTheCall: "Çağrıyı Sonlandır",
  t_holdOrUnholdTheCall: "Çağrıyı Beklet/Sürdür",
  t_limit: "Limit",
  t_playSound: "Ses Çal",
  t_lastFailedLogin: "Son Hatalı Giriş",
  t_displayQueueAbandonDetails: "Kaçan Çağrı Detayları",
  t_triggerImmediately: "Hemen Tetikle",
  t_mode: "Mod",
  t_triggerOnCrmCardOpen: "CRM Kartı Açıldığında Tetikle"
}

